import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { BsX } from 'react-icons/bs';

interface Props {
	title: string;
	children: React.ReactNode;
	onClose: () => void;
	show: boolean;
	className?: string;
	margin?: true;
	btn1: () => void;
	btn2?: () => void;
	btn3?: () => void;
}

const Modal: React.FC<Props> = ({ title, children, onClose, show, className, margin, btn1, btn2, btn3 }) => {
	// let [isOpen, setIsOpen] = useState(true);

	function closeModal() {
		// setIsOpen(false);
		onClose();
	}

	function openModal() {
		// setIsOpen(true);
	}

	return (
		<>
			<Transition appear show={show} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel
									className={`transform overflow-hidden rounded-2xl bg-white ${
										margin ? '' : 'p-6'
									} text-left align-middle shadow-xl transition-all ${className ? className : 'max-w-sm'}`}
								>
									{/* TODO - Allow the user to choose if they want the close button on the left or right side */}
									{margin ? (
										<>
											{/* Draw a title header at the top of the modal */}
											<div className={`relative w-full p-2 border-b-2 border-slate-200`}>
												<h3 className="font-medium text-xl ">{title}</h3>
												<BsX
													onClick={closeModal}
													size={32}
													className="absolute top-2 right-2 rounded-full text-white bg-red-500 hover:bg-red-400 cursor-pointer"
												/>
											</div>
											<div className="flex text-sm text-gray-500 overflow-y-auto">{children}</div>
										</>
									) : (
										<>
											{/* {title && (
												<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
													{title}
												</Dialog.Title>
											)} */}
											{/* Draw a title header at the top of the modal */}
											<div className="relative w-full pb-2 border-b-2 border-slate-200">
												{title && <h3 className="font-medium text-xl ">{title}</h3>}
												<BsX
													onClick={closeModal}
													size={32}
													className="absolute top-0 right-0 rounded-full text-white bg-red-500 hover:bg-red-400 cursor-pointer"
												/>
											</div>
											<div className="mt-2">
												<div className="text-sm text-gray-500 overflow-y-auto">{children}</div>
											</div>
										</>
									)}

									{/* <div className="mt-4">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
											onClick={closeModal}
										>
											Close
										</button>
									</div> */}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Modal;
