import { useEffect, useState } from 'react';
import { User } from '../../../api/Users';
import { API_ENDPOINT, serverURI } from '../../../functions/Constants';
import axios from 'axios';

export default ({ dataState }: { dataState: [User, React.Dispatch<React.SetStateAction<User>>] }) => {
	const [data, setData] = useState<{ integrations: { googleAuth: boolean } }>({ integrations: { googleAuth: false } });
	useEffect(() => {
		async function fetch() {
			const response: any = await axios.get(`${API_ENDPOINT}/gcal/check`, { withCredentials: true });
			if (response.data.integrations.googleAuth) {
				setData(response.data);
				console.log(response.data);
			}
		}
		fetch();
	}, []);
	return (
		<>
			<div className="flex flex-wrap flex-row gap-2 w-full">
				<div className="w-64 h-48 block border-2">
					<p className=" font-bold text-lg m-auto w-fit">Google Calendar</p>
					{data.integrations.googleAuth === true ? (
						<>
							<h1>Connected!</h1>
							<button>Revoke</button>
						</>
					) : (
						//  <h1>false</h1>
						<a href={`${serverURI}gcal/auth`} target="_blank" className="btn mt-4 block">
							Integrate
						</a>
					)}
				</div>
				<div className="w-64 h-48 block border-2">
					<p className=" font-bold text-lg m-auto w-fit">Zoom</p>
					<a href={`${serverURI}gcal/auth`} target="_blank" className="btn mt-4 block">
						Integrate
					</a>
				</div>
			</div>
		</>
	);
};
