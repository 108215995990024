import ContactTable from './dashboard';
import { STATE_ADD, STATE_DASHBOARD, STATE_EDIT, STATE_MANAGE } from '../../functions/Constants';
import ContactEdit from './edit';
import BusinessEdit from '../businesses/edit';
import ManageInit from './manage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useEffect } from 'react';
import { update } from '../../state/viewSlice';
import Scheduler from '../../features/scheduler/Scheduler';

const ContactRouteLanding: React.FC = () => {
	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();

	useEffect(() => {
		// Reset scene to dashboard
		dispatch(update({ state: STATE_DASHBOARD }));
	}, []);

	switch (viewState.state) {
		// case STATE_ADD:
		// 	if (viewState.type == 1) {
		// 		return <ContactEdit contact_id={''} />;
		// 	} else {
		// 		return <BusinessEdit business_id={''} />;
		// 	}
		// case STATE_EDIT:
		// 	if (viewState._id) {
		// 		if (viewState.type == 1) {
		// 			return <ContactEdit contact_id={viewState._id} />;
		// 		} else {
		// 			return <BusinessEdit business_id={viewState._id} />;
		// 		}
		// 	}
		// 	break;
		case STATE_MANAGE:
			if (viewState._id) {
				return <ManageInit _id={viewState._id} />;
			}
			break;
		case STATE_DASHBOARD:
		default:
			return (
				<>
					<ContactTable />
					<Scheduler /> {/* Modal */}
					<ContactEdit />
					<BusinessEdit />
				</>
			);
	}
};

// const ContactRouteLanding: React.FC = () => {
// 	const viewState = useSelector((state: RootState) => state.example.value);
// 	const dispatch = useDispatch();

// 	useEffect(() => {
// 		// Reset scene to dashboard
// 		dispatch(update({ state: STATE_DASHBOARD }));
// 	}, []);

// 	switch (viewState.state) {
// 		case STATE_DASHBOARD:
// 			return <ContactTable />;
// 		case STATE_ADD:
// 			if (viewState.type == 1) {
// 				return <ContactEdit contact_id={''} />;
// 			} else {
// 				return <BusinessEdit business_id={''} />;
// 			}
// 		case STATE_EDIT:
// 			if (viewState._id) {
// 				if (viewState.type == 1) {
// 					return <ContactEdit contact_id={viewState._id} />;
// 				} else {
// 					return <BusinessEdit business_id={viewState._id} />;
// 				}
// 			}
// 			break;
// 		case STATE_MANAGE:
// 			if (viewState._id) {
// 				return <ManageInit _id={viewState._id} />;
// 			}
// 			break;
// 		default:
// 			return <ContactTable />;
// 	}
// };

export default ContactRouteLanding;
