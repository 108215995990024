import { useCallback, useEffect, useRef, useState } from 'react';
import { BusinessEditProps, CONTACT_BUSINESS, STATE_ADD, STATE_DASHBOARD, STATE_EDIT } from '../../functions/Constants';
import { useToast } from '../../components/toast/ToastService';
import Contacts, { Contact } from '../../api/Contacts';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state/store';
import { update } from '../../state/viewSlice';
import Modal from '../../components/Modal';
import EditDetails from '../contacts/components/EditBusinessDetails';
import EditNotes from '../contacts/components/EditNotes';
import EditProjects from '../contacts/components/EditProjects';
import EditWork from '../contacts/components/EditWork';

const BusinessEdit: React.FC<BusinessEditProps> = ({ business_id }) => {
	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();
	const [currentTab, setCurrentTab] = useState<string>('');
	const titleSetRef = useRef(false); // Tracks if title has been set
	// const viewState = useSelector((state: RootState) => state.example.value);

	const [data, setData] = useState<Contact>({
		_id: '',
		title: '',
		tagline: '',
		industry: '',
		email: '',
		mobile: '',
		address: { street: '', city: '', postcode: 0, state: '', country: '' },
		relationship: 0,
		type: CONTACT_BUSINESS,
		associated: { business: [], person: [] },
		created: {
			date: '',
			by: '',
		},
	});
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	const [pageTitle, setPageTitle] = useState<string>('Add a new Business');
	// const pageTitle = useRef('Add a new Business');
	const toast = useToast();

	useEffect(() => {
		async function fetchObject() {
			if (viewState._id && viewState._id !== '') {
				await Contacts.get(viewState._id, setData, toast);
				titleSetRef.current = false; // Mark title as set
				// if (data && 'title' in data && data?.title) {
				// 	setPageTitle('Edit ' + data.title);
				// }
			}
		}
		if (viewState.type == CONTACT_BUSINESS) fetchObject();
	}, [viewState._id, viewState.type]);

	useEffect(() => {
		if (!titleSetRef.current && 'title' in data && data.title) {
			// Set the title only once when data has a valid name
			setPageTitle(`Edit ${data.title}`);
			titleSetRef.current = true; // Mark title as set
		}
	}, [data]);

	// Reset all values
	const handleClose = useCallback(async () => {
		titleSetRef.current = false; // Mark title as set
		setSubmitLock(false);
		setCurrentTab('details');
		// Clear data
		setData({
			_id: '',
			title: '',
			tagline: '',
			industry: '',
			email: '',
			mobile: '',
			address: { street: '', city: '', postcode: 0, state: '', country: '' },
			relationship: 0,
			type: CONTACT_BUSINESS,
			associated: { business: [], person: [] },
			created: {
				date: '',
				by: '',
			},
		});

		dispatch(update({ state: STATE_DASHBOARD, _id: '', type: undefined }));
	}, []);

	const renderSwitch = useCallback(
		(param: string) => {
			switch (param) {
				case 'work':
					return <EditWork dataState={[data, setData]} />;
				case 'projects':
					return <EditProjects dataState={[data, setData]} />;
				case 'notes':
					return <EditNotes dataState={[data, setData]} />;
				// Always default back to the 'details' tab
				case '':
				case 'default':
				case 'details':
				default:
					return <EditDetails dataState={[data, setData]} />;
			}
		},
		[data, setData]
	);

	return (
		<Modal
			className="w-[93%] max-w-[93%] h-[100dvh] max-h-[92dvh]"
			// show={true}
			show={viewState.type == CONTACT_BUSINESS && (viewState.state === STATE_ADD || viewState.state === STATE_EDIT) ? true : false}
			title={pageTitle}
			onClose={handleClose}
			btn1={handleClose}
			margin={true}
		>
			<div className="margin">
				<p className=" font-bold">Contact Settings</p>
				<ul>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('details')}
						>
							Personal details
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('work')}
						>
							Working status
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('projects')}
						>
							Projects
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('notes')}
						>
							Notes
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_security')}
						>
							Tags
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_security')}
						>
							Invoices
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_security')}
						>
							Custom fields
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_security')}
						>
							Membership Portal
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_')}
						>
							Notification Preferences
						</button>
					</li>
				</ul>
			</div>
			<div className="w-full">{renderSwitch(currentTab)}</div>
		</Modal>
	);
};

export default BusinessEdit;
