import Modal from '../../../components/Modal';
import { useEffect, useState } from 'react';
import { useToast } from '../../../components/toast/ToastService';
import SettingsUserWork from './SettingsUserWork';
import SettingsUserDetails from './SettingsUserDetails';
import SettingsUserSecurity from './SettingsUserSecurity';
import AccountEmailServices from './AccountEmailServices';
import AccountSecurity from './AccountSecurity';
import AccountDetails from './AccountDetails';
import handleSubmit from '../../../functions/handleSubmit';
import Users, { User } from '../../../api/Users';
import AccountIntegrations from './AccountIntegrations';

interface settingsModal {
	show: boolean;
	onclose: () => void;
}

export const Settings: React.FC = ({}) => {
	// const [showProfile, setShowProfile] = useState<boolean>(false);
	const [showSettings, setShowSettings] = useState<boolean>(false);
	return (
		<>
			<div>
				<br />
				<div>
					{/* <button className=" bg-purple-200" onClick={() => setShowProfile(true)}>
							Profile
						</button> */}
					<button className=" bg-purple-200" onClick={() => setShowSettings(true)}>
						Edit Settings
					</button>
				</div>
			</div>
			{/* <SettingsUserProfile show={showProfile} onclose={() => setShowProfile(false)} /> */}
			<SettingsUser show={showSettings} onclose={() => setShowSettings(false)} />
		</>
	);
};

// Modal for showing profile settings
export const SettingsUserProfile: React.FC<settingsModal> = ({ show, onclose }) => {
	return (
		<>
			<Modal className="max-w-[93%] max-h-[92dvh]" show={show} title="Profile" onClose={() => onclose()} btn1={() => onclose()}>
				<div className="form-container">
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault();
							const form = e.currentTarget;
							form.classList.add('was-validated');
						}}
					>
						<div className="flex flex-wrap flex-row">
							<div className="w-1/2 px-2">
								<label>First Name</label>
								<input type="text" />
							</div>
							<div className="w-1/2 px-2">
								<label>Last Name</label>
								<input type="text" />
							</div>
							<div className="w-1/2 px-2">
								<label>Email</label>
								<input type="email" />
							</div>
							<div className="w-1/2 px-2">
								<label>Phone</label>
								<input type="text" />
							</div>
						</div>
					</form>
					{/* <button className="btn btn-outline-success" onClick={() => handleCloseModal()}>
                            Save
                        </button>
                        <button className="btn btn-outline-danger" onClick={() => handleCloseModal()}>
                            Close
                        </button> */}
				</div>
			</Modal>
		</>
	);
};

// Modal for showing security settings
export const SettingsUser: React.FC<settingsModal> = ({ show, onclose }) => {
	// TODO - Seperate each settings as a diffferent component. Also seperate and handle data and saving accordingly
	const [data, setData] = useState<User>({
		_id: '',
		name: { first: '', middle: '', last: '' },
		username: '',
		dob: '',
		gender: 0,
		email: '',
		phone: '',
		mobile: '',
		address: { street: '', city: '', postcode: 0, state: '', country: '' },
		settings: {
			smtp: [
				{
					_id: '',
					method: '',
					service: '',
					hostname: '',
					port: 0,
					username: '',
					password: '',
				},
			],
		},
	});
	const [currentTab, setCurrentTab] = useState<string>('');
	const [submitLock, setSubmitLock] = useState<boolean>(false);

	const toast = useToast();

	useEffect(() => {
		async function fetchObject() {
			// await getCurrentUserObject(setData);
			await Users.current(setData);
		}
		fetchObject();
	}, []);

	// TODO - pass user settings data back sowe can update the user from here.
	function renderSwitch(param: string) {
		switch (param) {
			case 'user_security':
				return <SettingsUserSecurity />;
			case 'user_work':
				return <SettingsUserWork />;
			case 'account_details':
			// return <AccountDetails data={[data, setData]} />;
			case 'account_security':
				return <AccountSecurity />;
			case 'account_email_services':
				return <AccountEmailServices dataState={[data, setData]} />;
			case 'user_integrations':
			case 'account_integrations':
				return <AccountIntegrations dataState={[data, setData]} />;
			case '':
			case 'default':
			case 'user_details':
			default:
				return <SettingsUserDetails dataState={[data, setData]} />;
		}
	}

	return (
		<>
			<Modal
				className="max-w-[93%] w-[93%] h-[100dvh] max-h-[92dvh]"
				show={show}
				title="User Settings"
				onClose={() => onclose()}
				btn1={() => onclose()}
				margin={true}
			>
				<div className="margin">
					<p className=" font-bold">User Settings</p>
					<ul>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_details')}
							>
								Personal details
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_work')}
							>
								Working status
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_security')}
							>
								Security
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Notifications
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Display Preferences
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_integrations')}
							>
								Integrations
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('account_email_services')}
							>
								Email Services
							</button>
						</li>
					</ul>
					<br />
					<br />
					<p className=" font-bold">Account Settings</p>
					<ul>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('account_details')}
							>
								Business details
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_work')}
							>
								Working status
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('account_security')}
							>
								Security
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Notifications
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Display Preferences
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Integrations
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('account_email_services')}
							>
								Email Services
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Billing
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Team Members
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('user_')}
							>
								Custom fields
							</button>
						</li>
						<li>
							<button
								type="button"
								className="no-btn w-full text-left p-1 hover:bg-slate-300"
								onClick={() => setCurrentTab('account_integrations')}
							>
								Integrations
							</button>
						</li>
					</ul>
				</div>
				<div className="flex p-6 w-full">
					<div className="form-container w-full">
						<form noValidate onSubmit={(e) => handleSubmit(e, [data, setData], { post: Users.post, put: Users.put })}>
							{renderSwitch(currentTab)}
							<div className="absolute bottom-0 w-full">
								<button type="submit" className=" bg-emerald-500 hover:bg-emerald-600" disabled={submitLock}>
									{submitLock ? 'Loading...' : 'Save'}
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	);
};
