import axios from 'axios';
import { _id, API_ENDPOINT } from '../functions/Constants';
import { catchError } from '../functions/handleLoginAuth';
import { EmailSettings } from '../pages/settings/components/AccountEmailServices';

const API_ENDPOINT_ACCOUNTS = API_ENDPOINT + '/account';
const API_ENDPOINT_ACCOUNTS_SETTINGS = API_ENDPOINT_ACCOUNTS + '/settings';

export interface Account {
	_id: _id;
	title?: string;
	tagline?: string;
	industry?: string;
	website?: string;
	username: string;
	email?: string;
	phone?: string;
	mobile?: string;
	settings?: AccountSettings;
	address?: { street?: string; city?: string; postcode?: number; state?: string; country?: string };
}

export interface AccountSettings {
	smtp?: EmailSettings[];
}

// Account holders with login are users
// export interface Account {
// 	_id: _id;
// 	name?: { first?: string; middle?: string; last?: string };
// 	dob?: string;
// 	username: string;
// 	gender?: number;
// 	email?: string;
// 	settings?: [{ key?: string }]; // This allows any string key
// 	phone?: string;
// 	mobile?: string;
// 	address?: { street?: string; city?: string; postcode?: number; state?: string; country?: string };
// }

/**
 * Accounts API endpoint
 */
const Accounts = {
	/**
	 * Get a single object based on an id.
	 *
	 * @param   account String or object used as an id
	 * @param   setData Set Function used to update an object
	 */
	async get(
		account: _id | Account,
		setData: React.Dispatch<React.SetStateAction<Account>> | React.Dispatch<React.SetStateAction<Account>>,
		toast?: any
	) {
		try {
			const response = await axios.get(API_ENDPOINT_ACCOUNTS + `/${typeof account === 'string' ? account : account._id}`, {
				withCredentials: true,
			});
			if (response.data) {
				setData(response.data);
			}
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Get a single object based on an id.
	 *
	 * @param   _id String or object used as an id
	 * @param   setData Set Function used to update an object
	 * @alias this.get()
	 */
	async findById(_id: _id | Account, setData: React.Dispatch<React.SetStateAction<Account>>) {
		this.get(_id, setData);
	},

	/**
	 * Get a single object based on an id.
	 *
	 * @param   account String or object used as an id
	 * @param   setData Set Function used to update an object
	 */
	async current(setData: React.Dispatch<React.SetStateAction<Account>> | React.Dispatch<React.SetStateAction<Account>>, toast?: any) {
		try {
			const response = await axios.get(API_ENDPOINT_ACCOUNTS + `/current`, {
				withCredentials: true,
			});
			if (response.data) {
				setData(response.data);
			}
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Create a new record in the database
	 *
	 * @param   account Object used
	 */
	async post(data: Account, toast?: any) {
		// TODO - show error on fail
		try {
			const response = await axios.post(API_ENDPOINT_ACCOUNTS, { ...data }, { withCredentials: true });
			return response.data;
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update a record in the database
	 *
	 * @param   data Object used
	 */
	async put(data: Account, toast?: any) {
		try {
			await axios.put(API_ENDPOINT_ACCOUNTS + `/${data._id}`, { ...data }, { withCredentials: true });
		} catch (error) {
			catchError(error, toast);
		}
	},

	/**
	 * Update partial details of a record in the database
	 *
	 * @param   data Object used
	 */
	async patch(data: Account) {
		this.put(data);
	},

	/**
	 * Update partial details of a record in the database
	 *
	 * @param   account Object used
	 */
	async delete(account: _id | Account, toast?: any) {
		try {
			await axios.delete(API_ENDPOINT_ACCOUNTS + `/${typeof account === 'string' ? account : account._id}`, {
				withCredentials: true,
			});
		} catch (error) {
			catchError(error, toast);
		}
	},

	settings: {
		/**
		 * Get a single object based on an id.
		 *
		 * @param   account String or object used as an id
		 * @param   setData Set Function used to update an object
		 */
		async get(
			setData: React.Dispatch<React.SetStateAction<any>> | React.Dispatch<React.SetStateAction<any>>,
			query?: string,
			toast?: any
		) {
			try {
				const response = await axios.get(`${API_ENDPOINT_ACCOUNTS_SETTINGS}/${query ? `${query}` : ''}`, {
					withCredentials: true,
				});
				if (response.data) {
					setData(response.data[0]);
				}
			} catch (error) {
				catchError(error, toast);
			}
		},

		async post<T extends Record<string, any>>(data: T, query?: string, toast?: any) {
			try {
				const response = await axios.post(`${API_ENDPOINT_ACCOUNTS_SETTINGS}${query}`, data, { withCredentials: true });
				return response.data;
			} catch (error) {
				catchError(error, toast);
			}
		},

		/**
		 * Update a record in the database
		 *
		 * @param   data Object used
		 */
		async put<T extends Record<string, any>>(data: T, query?: string, toast?: any) {
			try {
				await axios.put(`${API_ENDPOINT_ACCOUNTS_SETTINGS}${query}`, data, { withCredentials: true });
			} catch (error) {
				catchError(error, toast);
			}
		},

		/**
		 * Update partial details of a record in the database
		 *
		 * @param   contact Object used
		 */
		async delete<T extends Record<string, any>>(data: T, query?: string, toast?: any) {
			try {
				await axios.delete(`${API_ENDPOINT_ACCOUNTS_SETTINGS}${query}`, {
					withCredentials: true,
				});
			} catch (error) {
				catchError(error, toast);
			}
		},
	},
};

export default Accounts;
