import { BsClockHistory, BsPerson, BsPlusCircleDotted } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import Projects, { Project } from '../../../api/Projects';
import { Contact } from '../../../api/Contacts';

export default ({ dataState }: { dataState: [Contact, React.Dispatch<React.SetStateAction<Contact>>] }) => {
	const [contactData, setContactData] = dataState;
	const [projectData, setProjectData] = useState<Project[] | null>([]);
	const [editing, setEditing] = useState<Project | null>(null);
	const [data, setData] = useState<Project>({
		_id: null,
		title: null,
		description: null,
		priority: 3,
		status: 1, // Not started
		assigned: {
			contacts: [],
			users: [],
		},
		pricing: {
			cost: null,
			method: null,
			direction: null,
			interval: null,
			units: null,
			time: null,
			promotion: null,
			estimated: {
				cost: null,
				time: null,
			},
		},
		due: null,
	});
	useEffect(() => {
		async function fetch() {
			await Projects.get(setProjectData, 'contact=' + contactData._id);
		}
		fetch();
	}, [dataState]);

	return (
		<>
			<div className="flex flex-wrap flex-row">
				<div className="w-full flex">
					{/* TODO - Flip animation when clicked to reveal settings */}
					{projectData?.length &&
						projectData?.length > 0 &&
						projectData &&
						projectData.map((project: any, index: number) => (
							// TODO - Allow project colour
							<div
								key={index} // Use project ID for efficient keying
								className={`relative flex flex-wrap flex-col w-auto min-w-60 px-4 py-4 bg-amber-50 border-amber-100 border-2 cursor-pointer ${
									editing === project ? 'editing' : ''
								}`}
								// onClick={() => (editingNote && editingNote._id == project._id ? cancelEdit() : processEditNote(project))}
							>
								{/* {editingNote && editingNote._id === project._id ? ( // Edit project
								<EditNote // Render editNote component when editing
									data={data}
									setData={setData}
									handleSubmit={handleSubmit}
									handleDelete={handleDelete}
									submitLock={submitLock}
									onCancel={cancelEdit} // Added cancel functionality
								/> 
								) : (
									// Show Note
									<>
										<p className="text-xl font-bold ">{project.title}</p>
										<p className="pb-12  whitespace-pre-line">{project.body}</p>
										<div className="absolute bottom-2">
											<span>Created {new Date(project.created.date).toLocaleString('en-AU')} by Drae Ockenden</span>
										</div>
									</>
								)} */}
							</div>
						))}
					{/* <span className="border-2 border-slate-300 bg-slate-50 h-[32em] w-[24em]  cursor-pointer min-w-32 block mx-2 relative">
						<img src="https://picsum.photos/160" className="m-auto py-1 rounded-2xl border-2 border-slate-100 " />
						<div className="p-4">
							<h3 className="text-xl">Website design</h3>
						</div>
						<div className="p-4">
							<ul>
								<li>
									<span>$7,200</span>
								</li>
								<li>
									<BsClockHistory size={18} className="inline" />
									<span className="ml-2">86 hours</span>
								</li>
								<li>
									<BsPerson size={16} className="inline" />
									<span className="ml-2">Harry Smart</span>
								</li>
								<li>
									<span className="bg-amber-400 text-black px-4">In Progress</span>
								</li>
								<li className="absolute bottom-0">
									<div className="mb-1">
										<span>Due on 12/09/2024</span>
									</div>
								</li>
							</ul>
						</div>
					</span> */}
					{/* TODO - Flip animation when clicked to reveal settings */}
					{/* <span className="border-2 border-slate-300 bg-slate-50 h-[32em] w-[24em]  cursor-pointer min-w-32 block mx-2 relative">
						<div className="p-4">
							<h3 className="text-xl">SEO review</h3>
						</div>
						<div className="p-4">
							<ul>
								<li>
									<span>$320</span>
								</li>
								<li>
									<BsClockHistory size={18} className="inline" />
									<span className="ml-2">46 minutes</span>
								</li>
								<li>
									<BsPerson size={16} className="inline" />
									<span className="ml-2">Ragenia George</span>
								</li>
								<li>
									<span className="bg-amber-400 text-black px-4">In Progress</span>
								</li>
								<li className="absolute bottom-0">
									<div className="mb-1">
										<span>Due in 38 hours</span>
									</div>
								</li>
							</ul>
						</div>
					</span> */}
					<span className="border-2 border-slate-200 bg-stone-100 h-[32em] w-[24em] cursor-pointer min-w-32 block mx-2 relative">
						<div className=" top-1/3 m-auto block relative">
							<p className=" py-4 text-center text-lg font-bold">Add new project</p>
							<BsPlusCircleDotted size={48} className=" m-auto" />
						</div>
					</span>
				</div>
			</div>
		</>
	);
};
