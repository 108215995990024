import { BsPlusCircleDotted } from 'react-icons/bs';
import { User } from '../../../api/Users';
import { useCallback, useEffect, useState } from 'react';
import handleOnChange from '../../../functions/handleOnChange';
import Accounts from '../../../api/Accounts';
import { _id } from '../../../functions/Constants';
import { useToast } from '../../../components/toast/ToastService';
import { catchError } from '../../../functions/handleLoginAuth';
import { Select } from '../../../components/Select';
import { SiGmail, SiMicrosoftoutlook } from 'react-icons/si';
import { toastUnknownError } from '../../../components/toast/ToastTemplates';

export type EmailSettings = {
	_id: _id;
	method: string;
	service: string;
	hostname: string;
	port: string | number; // Handle as a string to avoid conversion issues
	username: string;
	password: string;
};

interface EditAccountProps {
	data: EmailSettings;
	setData: (newData: EmailSettings) => void;
	handleSubmit: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	handleDelete: () => void;
	submitLock: boolean;
	onCancel: () => void;
}

export default ({ dataState }: { dataState: [User, React.Dispatch<React.SetStateAction<User>>] }) => {
	const [account, setAccount] = dataState; // Initialize with the type
	const [editing, setEditing] = useState<EmailSettings | null>(null);
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	const toast = useToast();
	const [data, setData] = useState<EmailSettings>({
		_id: '',
		method: '',
		service: '',
		hostname: '',
		port: 0,
		username: '',
		password: '',
	}); // Initialize with the type

	useEffect(() => {
		async function fetchData() {
			await Accounts.settings.get(setAccount, `?_id=657e36fc0ada8b0090248d41`);
		}
		fetchData();
	}, []);

	async function onSubmit() {
		// if
		await Accounts.settings.post(data, `?_id=657e36fc0ada8b0090248d41`);
	}

	const handleSubmit = useCallback(
		async (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
			e.stopPropagation();
			setSubmitLock(true);
			if (data.hostname === '' || data.port === undefined || data.username === '' || data.password === '') {
				const toastId = toast.open(
					<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Missing Fields!</p>
							<p>Please complete all required fields.</p>
						</div>
					</div>,
					5000
				);
			} else {
				const updatedNote = { ...data }; // Create a copy to avoid mutation
				if (editing && data._id && account.settings.smtp) {
					updatedNote._id = editing._id;
					const response = await Accounts.settings.put(account.settings.smtp, '');
					// if (response._id !== '') {
					// 	// TODO - When a note is created, flip around to show the 'note card'
					// 	if (account.settings.smtp) {
					// 		const noteIndex = account.settings.smtp.findIndex((note) => note._id === response._id);

					// 		// Replace the old note with the updated note
					// 		if (noteIndex !== -1) {
					// 			account.settings.smtp[noteIndex] = response;
					// 		}
					// 	} else {
					// 		account.settings.smtp = [response];
					// 	}
					// }
					setEditing(null); // Clear buffer
					setData({
						_id: '',
						method: '',
						service: '',
						hostname: '',
						port: 0,
						username: '',
						password: '',
					}); // Clear buffer
					const toastId = toast.open(
						<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
							<div></div>
							<div>
								<p>Note updated!</p>
							</div>
						</div>,
						8000
					);
				} else if (account.settings.smtp) {
					const response = await Accounts.settings.post(account.settings.smtp, '?_id=657e36fc0ada8b0090248d41');
					if (response._id !== '') {
						// TODO - When a note is created, flip around to show the 'note card'
						if (account.settings && account.settings.smtp) {
							account.settings.smtp = [...account.settings.smtp, response];
						} else {
							if (!account.settings) {
								account.settings = {
									smtp: [
										{
											_id: '',
											method: '',
											service: '',
											hostname: '',
											port: 0,
											username: '',
											password: '',
										},
									],
								};
							}

							account.settings.smtp = [response];
						}
					}
					setData({
						_id: '',
						method: '',
						service: '',
						hostname: '',
						port: 0,
						username: '',
						password: '',
					}); // Clear buffer
					const toastId = toast.open(
						<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
							<div></div>
							<div>
								<p>Note added!</p>
							</div>
						</div>,
						8000
					);
				} else {
					const toastId = toast.open(toastUnknownError, 8000);
				}
			}
			setSubmitLock(false);
		},
		[data, editing, account.settings]
	);

	const processEditNote = useCallback((note: EmailSettings) => {
		setEditing(note);
		setData({ ...note }); // Pre-populate edit form with note data
	}, []);

	const cancelEdit = useCallback(() => {
		setEditing(null);
		setData({
			_id: '',
			method: '',
			service: '',
			hostname: '',
			port: 0,
			username: '',
			password: '',
		}); // Reset edit state
	}, []);

	const handleDelete = useCallback(() => {
		const updatedNote = { ...data }; // Create a copy to avoid mutation
		if (editing && updatedNote) {
			updatedNote._id = editing._id;
			try {
				// Accounts.settings.delete(account.settings.smtp, data, toast);
			} catch (error) {
				catchError(error);
			}
		} else {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-emerald-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Error!</p>
						<p>Please try again.</p>
					</div>
				</div>,
				8000
			);
		}
	}, [data, editing, account.settings]);

	return (
		<>
			<div className="grid gap-4 grid-cols-4 mb-8">
				{/* {account.settings &&
					account.settings.smtp.map((smtp: any, index: number) => (
						<EditSMTP
							data={data}
							setData={setData}
							handleSubmit={handleSubmit}
							handleDelete={handleDelete}
							submitLock={submitLock}
							onCancel={cancelEdit} // Added cancel functionality
						/>
					))} */}
				{account.settings &&
					account.settings.smtp &&
					account.settings.smtp.map((note: any, index: number) => (
						// TODO - Allow note colour
						<div
							key={index} // Use note ID for efficient keying
							className={`relative flex flex-wrap flex-col w-auto min-w-60 px-4 py-4 bg-cyan-50 border-cyan-100 border-2 cursor-pointer ${
								editing === note ? 'editing' : ''
							}`}
							onClick={() => (editing && editing._id == note._id ? cancelEdit() : processEditNote(note))}
						>
							{/* TODO - When the note is clicked, spin it around revealing the edit settings */}
							{editing && editing._id === note._id ? ( // Edit note
								<EditSMTP // Render editNote component when editing
									data={data}
									setData={setData}
									handleSubmit={handleSubmit}
									handleDelete={handleDelete}
									submitLock={submitLock}
									onCancel={cancelEdit} // Added cancel functionality
								/>
							) : (
								<>
									<p className="text-xl font-bold ">{note.method}</p>
									<p className="pb-12  whitespace-pre-line">{note.username}</p>
									{/* <div className="absolute bottom-2">
										<span>Created {new Date(note.created.date).toLocaleString('en-AU')} by Drae Ockenden</span>
									</div> */}
								</>
							)}
						</div>
					))}
				{/* <input type="button" onClick={onSubmit} value="save" /> */}
			</div>
			{!editing && (
				<div className="relative flex flex-wrap flex-col w-1/3 px-4 py-4 bg-cyan-50 border-cyan-100 border-2">
					{/* TODO - maybe show a blank area with a "click to add" type thing that the edit settings can switch positions with */}
					<EditSMTP // Render editNote component when editing
						data={data}
						setData={setData}
						handleSubmit={(e) => handleSubmit(e)}
						handleDelete={handleDelete}
						submitLock={submitLock}
						onCancel={cancelEdit} // Added cancel functionality
					/>
				</div>
			)}
		</>
	);
};

const EditSMTP = ({ data, setData, handleSubmit, handleDelete, submitLock, onCancel }: EditAccountProps) => {
	const handleSelectChange = useCallback((e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		// set method
		// console.log(e._id);
		handleOnChange(e, ['method'], setData);
		// update hostname
		// setData((prev: EmailSettings) => {
		// 	let updatedData = { ...prev };
		// 	updatedData.hostname = 'smtp.gmail.com';
		// 	return updatedData;
		// });
		// update port
	}, []);

	console.log(data);
	return (
		<>
			<div className="w-full">
				<div className="w-full p-3">
					<label>SMTP method</label>
					<Select
						value={data.method || 'other'}
						options={[
							{ _id: 'other', label: 'Other', svg: SiMicrosoftoutlook },
							{ _id: 'gmail', label: 'Gmail', svg: SiGmail },
							{ _id: 'outlook', label: 'Outlook', svg: SiMicrosoftoutlook },
							{ _id: 'axigen', label: 'Axigen', svg: SiMicrosoftoutlook },
							{ _id: 'sendgrid', label: 'Sendgrid', svg: SiMicrosoftoutlook },
							{ _id: 'awsses', label: 'AWS SES', svg: SiMicrosoftoutlook },
						]}
						onChange={(e: any) => handleSelectChange(e)}
						onClick={(e: any) => e.stopPropagation()} // Prevent parent onClick from firing
						autoClose // Close the dialog after making a selection
					></Select>
					{/* <select
						onChange={(e) => handleOnChange(e, ['smtp', 'method'], setData)}
						onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
					>
						<option value="gmail" selected={'method' in data && data.method === 'gmail'}>
							Gmail
						</option>
						<option value="outlook" selected={'method' in data && data.method === 'outlook'}>
							Outlook
						</option>
						<option value="sendgrid" selected={'method' in data && data.method === 'sendgrid'}>
							Sendgrid
						</option>
						<option value="axigen" selected={'method' in data && data.method === 'axigen'}>
							Axigen
						</option>
						<option value="other" selected={'method' in data && data.method === 'other'}>
							Custom SMTP
						</option>
					</select> */}
				</div>
				<div className="w-full p-3">
					<label className="required">Service Name</label>
					<input
						type="text"
						autoComplete="off"
						required
						defaultValue={data.service}
						onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
						onChange={(e) => handleOnChange(e, ['service'], setData)}
					/>
				</div>
				{data.method === 'other' && (
					<div className="w-full p-3">
						<label className="required">Hostname</label>
						<input
							type="text"
							autoComplete="off"
							required
							defaultValue={data.hostname}
							onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
							onChange={(e) => handleOnChange(e, ['hostname'], setData)}
						/>
					</div>
				)}
				{data.method === 'other' && (
					<div className="w-full p-3">
						<label className="required">Port</label>
						<input
							type="text"
							autoComplete="off"
							placeholder="465"
							required
							defaultValue={data.port}
							onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
							onChange={(e) => handleOnChange(e, ['port'], setData)}
						/>
					</div>
				)}
				<div className="w-full p-3">
					<label className="required">Username</label>
					<input
						type="text"
						autoComplete="off"
						required
						defaultValue={data.username}
						onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
						onChange={(e) => handleOnChange(e, ['username'], setData)}
					/>
				</div>
				<div className="w-full p-3">
					<label className="required">Password</label>
					<input
						type="text"
						autoComplete="off"
						required
						defaultValue={data.password}
						onClick={(e) => e.stopPropagation()} // Prevent parent onClick from firing
						onChange={(e) => handleOnChange(e, ['password'], setData)}
					/>
					{data.method === 'gmail' && <div className="small-help ">You must generate an app password and use that instead</div>}
				</div>
				<input type="button" onClick={(e) => handleSubmit(e)} value="save" />
			</div>
		</>
	);
};
