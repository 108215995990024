import { useEffect, useRef, useState } from 'react';
import { IconType } from 'react-icons';
import { BsCaretDownFill, BsCheck2, BsX } from 'react-icons/bs';
import { _id } from '../functions/Constants';

export type SelectOption = {
	_id: _id;
	label: string;
	svg?: IconType;
};

type SingleSelectProps<T> = {
	multiple?: false;
	value?: _id | T;
	onChange: (value: _id | T) => void;
};

type MultipleSelectProps<T> = {
	multiple: true;
	value?: _id[] | T[];
	// value?: SelectOption[];
	onChange: (value: _id[]) => void;
};

type SelectProps<T> = {
	options?: SelectOption[];
	search?: boolean;
	onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	autoClose?: true;
} & (SingleSelectProps<T> | MultipleSelectProps<T>);

export function Select<T extends { _id: string }>({ value, options, onChange, onClick, multiple, autoClose }: SelectProps<T>) {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
	const selectRef = useRef<HTMLDivElement>(null); // Reference to the Select container

	// Close dropdown if clicking outside
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
				setIsOpen(false); // Close the dropdown when clicking outside
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	function clearOptions() {
		multiple ? onChange([]) : onChange('');
	}

	function selectOption(option: _id) {
		if (multiple) {
			if (value) {
				if (isOptionSelected(option)) {
					onChange(value.filter((v) => (typeof v === 'string' ? v !== option : v._id !== option)) as _id[]);
				} else {
					onChange([...(Array.isArray(value) ? value : []), option] as _id[]);
				}
				// if (isOptionSelected(option)) {
				// 	if (typeof value === 'string') {
				// 		onChange(Array.isArray(value) ? value.filter((id) => id !== option) : []);
				// 	}
				// } else {
				// 	onChange([...(Array.isArray(value) ? value : []), option]);
				// }
			} else {
				// ERROR HAPPENED
			}
		} else {
			if (option !== value) onChange(option || '');
		}
		if (autoClose || !multiple) setIsOpen(false);
	}

	// function isOptionSelected(option: SelectOption) {
	// 	if (multiple) {
	// 		if (value) {
	// 			return value.some((v: SelectOption) => v._id === option._id);
	// 		} else {
	// 			// ERROR HAPPENED
	// 			return false;
	// 		}
	// 	} else {
	// 		return value && option._id === value._id;
	// 	}
	// }

	function isOptionSelected(option: _id) {
		// v4
		if (multiple && Array.isArray(value)) {
			// If value is an array, check if it contains the option
			return value.some((v) => (typeof v === 'string' ? v === option : (v as T)._id === option));
		} else if (typeof value === 'object' && value !== null && '_id' in value) {
			// If value is an object, compare its _id
			return (value as T)._id === option;
		} else {
			// If value is a string, compare it directly
			return value === option;
		}
		// v3
		// if (multiple) {
		// 	return Array.isArray(value) ? value.includes(option) : false;
		// } else {
		// 	return typeof value === 'object' && value !== null ? value._id === option : value === option;
		// }
		// return multiple
		// 	? value?.some((v) => v._id === option._id)
		// 	: option._id === (typeof value === 'object' && '_id' in value ? value?._id : typeof value === 'string' ? value : '');
		// v2
		// return multiple ? value?.includes(option!) : option === (typeof value === 'string' ? value : '');
	}

	useEffect(() => {
		if (isOpen) setHighlightedIndex(0);
	}, [isOpen]);

	// Find corresponding label by matching the value to the options
	// function getLabel(value: SelectOption | _id | undefined): string | null {
	// 	if (!options || !value) return null;
	// 	// If value is an object (for single select case), return its label
	// 	if (typeof value === 'object' && 'label' in value) {
	// 		return value.label;
	// 	}
	// 	// If value is just an _id (for single select), find the corresponding label from options
	// 	if (typeof value === 'string') {
	// 		const selectedOption = options.find((option) => option._id === value);
	// 		return selectedOption ? selectedOption.label : null;
	// 	}
	// 	return null;
	// }

	// Find corresponding label by matching the value to the options
	function getLabel(value: _id | _id[] | T | T[] | undefined): string | null {
		if (!options || !value) return null;
		if (typeof value === 'object' && '_id' in value) {
			// If value is an object, return its label
			const selectedOption = options.find((option) => option._id === value._id);
			return selectedOption ? selectedOption.label : null;
		}
		if (typeof value === 'string') {
			// If value is just an _id, find the corresponding label from options
			const selectedOption = options.find((option) => option._id === value);
			return selectedOption ? selectedOption.label : null;
		}
		return null;
	}

	// Get the label for a given ID
	function getLabelById(_id?: _id | _id[] | null): string {
		if (_id) {
			const option = options?.find((opt) => opt._id === _id);
			return option?.label || '';
		}
		return '';
	}

	return (
		<div
			// onBlur={() => setIsOpen(false)}
			ref={selectRef} // Attach the ref to the outermost div
			onClick={() => setIsOpen((prev) => !prev)}
			onMouseLeave={() => {
				setHighlightedIndex(null);
			}}
			className="relative min-h-4 flex items-center gap-2 p-2 outline-none rounded-md border-orange-200 border-2 cursor-pointer"
		>
			<span className=" flex-grow flex gap-2 flex-wrap">
				{multiple && value
					? value.map((v: _id | T) => (
							<button
								key={typeof v === 'string' ? v : v._id}
								type="button"
								onClick={(e) => {
									e.stopPropagation();
									selectOption(typeof v === 'string' ? v : v._id);
								}}
								className="flex py-0 px-3 w-auto items-center m-0 bg-orange-300 hover:bg-orange-400 "
							>
								{getLabel(typeof v === 'string' ? v : v._id)}
								<span className="">
									<BsX />
								</span>
							</button>
						))
					: getLabel(value)}
				{/* // getLabel doesn't allow arrays */}
				{/* Show label for single select */}
				{/* // : typeof value === 'object' && 'label' in value // ? value?.label // : typeof value === 'string' // ? value // : '' */}
			</span>
			<button
				type="button"
				onClick={(e) => {
					e.stopPropagation();
					clearOptions();
				}}
				className="border-none outline-none bg-inherit hover:bg-inherit w-auto m-0 p-0 text-inherit rounded-none pointer"
			>
				<BsX />
			</button>
			<div className=" bg-slate-400 self-stretch w-[0.05rem]"></div>
			<BsCaretDownFill size={10} className="min-w-2 min-h-2" />
			<ul
				className={`select-list absolute glass bg-blue-300/20 p-2 max-h-80 overflow-y-auto m-0 list-none rounded-md border-gray-200 left-0 w-full top-[calc(100%+0.25em)] z-10 ${
					isOpen ? 'block' : 'hidden'
				}`}
			>
				{options &&
					options.map((option: SelectOption, index) => (
						<li
							onClick={(e) => {
								e.stopPropagation();
								selectOption(option._id);
							}}
							onMouseEnter={() => {
								setHighlightedIndex(index);
							}}
							key={option._id}
							className={`p-1 cursor-pointer rounded my-1 ${isOptionSelected(option._id) ? 'bg-slate-400/40' : ''} ${
								typeof highlightedIndex === 'number' && index === highlightedIndex
									? ' outline-blue-600/40 outline-2 outline'
									: ''
							}`}
						>
							{multiple && (
								<span className="relative inline-block rounded border-slate-600 border-2 size-4 mr-1">
									{isOptionSelected(option._id) && <BsCheck2 size={12} className="" />}
								</span>
							)}
							{option.svg && <option.svg className="mr-2 inline-block" />}
							{option.label}
						</li>
					))}
			</ul>
		</div>
	);
}
