import { BsClockHistory, BsPerson, BsPlusCircleDotted } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import Projects, { Project } from '../../../api/Projects';
import { Contact } from '../../../api/Contacts';
import SearchSelect from '../../../components/Combobox';

export default ({ dataState }: { dataState: [Contact, React.Dispatch<React.SetStateAction<Contact>>] }) => {
	const [contactData, setContactData] = dataState;
	const [projectData, setProjectData] = useState<Project[] | null>(null);
	const [editing, setEditing] = useState<Project | null>(null);
	const [data, setData] = useState<Project>({
		_id: null,
		title: null,
		description: null,
		priority: 3,
		status: 1, // Not started
		assigned: {
			contacts: [],
			users: [],
		},
		pricing: {
			cost: null,
			method: null,
			direction: null,
			interval: null,
			units: null,
			time: null,
			promotion: null,
			estimated: {
				cost: null,
				time: null,
			},
		},
		due: null,
	});
	useEffect(() => {
		async function fetch() {
			await Projects.get(setProjectData, 'contact=' + contactData._id);
		}
		fetch();
	}, [dataState]);

	return (
		<>
			<div className="flex flex-wrap flex-row">
				<table>
					<thead>
						<tr>
							<th>&nbsp;</th>
							<th>Name</th>
							<th>ID</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<input type="checkbox" />
							</td>
							<td>Networking</td>
							<td>networking</td>
						</tr>
						<tr>
							<td>
								<input type="checkbox" />
							</td>
							<td>Affiliate</td>
							<td>affiliate</td>
						</tr>
					</tbody>
				</table>
				<SearchSelect />
				{/* <div className="w-full flex">
				</div> */}
			</div>
		</>
	);
};
