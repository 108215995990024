import { useCallback, useEffect, useRef, useState } from 'react';
import { CONTACT_PERSON, ContactEditProps, STATE_ADD, STATE_DASHBOARD, STATE_EDIT } from '../../functions/Constants';
import { useToast } from '../../components/toast/ToastService';
import { toastPostSuccessManageLink } from '../../components/toast/ToastTemplates';
import Modal from '../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { update } from '../../state/viewSlice';
import EditDetails from './components/EditDetails';
import EditWork from './components/EditWork';
import EditProjects from './components/EditProjects';
import EditNotes from './components/EditNotes';
import Contacts, { Contact } from '../../api/Contacts';
import EditTags from './components/EditTags';
import { RootState } from '../../state/store';

const ContactEdit: React.FC<ContactEditProps> = ({ contact_id }) => {
	const [currentTab, setCurrentTab] = useState<string>('');
	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();

	// TODO - Might change useState to UseRef instead to prevent the page re-rendering unneccecarily
	const [data, setData] = useState<Contact>({
		_id: '',
		name: { first: '', middle: '', last: '' },
		dob: '',
		gender: 0,
		email: '',
		mobile: '',
		address: { street: '', city: '', postcode: 0, state: '', country: '' },
		relationship: 0,
		type: CONTACT_PERSON,
		associated: { business: [], person: [] },
		created: {
			date: '',
			by: '',
		},
	});
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	// const pageTitle = useRef('Add a new contact');
	const [pageTitle, setPageTitle] = useState<string>('Add a new Business');
	const titleSetRef = useRef(false); // Tracks if title has been set
	const toast = useToast();
	useEffect(() => {
		async function fetchObject() {
			if (viewState._id && viewState._id != '') {
				await Contacts.get(viewState._id, setData);
				titleSetRef.current = false; // Mark title as set
				// if (data && 'name' in data && data?.name?.first) {
				// 	setPageTitle('Edit ' + data.name.first + ' ' + (data.name.last ?? ''));
				// }
			} else {
				// Reset
				setData({
					_id: '',
					name: { first: '', middle: '', last: '' },
					dob: '',
					gender: 0,
					email: '',
					mobile: '',
					address: { street: '', city: '', postcode: 0, state: '', country: '' },
					relationship: 0,
					type: CONTACT_PERSON,
					associated: { business: [], person: [] },
					created: {
						date: '',
						by: '',
					},
				});
			}
		}
		if (viewState.type == CONTACT_PERSON) fetchObject();
	}, [viewState._id, viewState.type]);

	useEffect(() => {
		if (!titleSetRef.current && 'name' in data && data?.name?.first) {
			// Set the title only once when data has a valid name
			setPageTitle(`Edit ${data.name.first} ${data.name.last ?? ''}`);
			titleSetRef.current = true; // Mark title as set
		}
	}, [data]);

	// Reset all values
	const handleClose = useCallback(async () => {
		titleSetRef.current = false; // Mark title as set
		setSubmitLock(false);
		setCurrentTab('details');
		setPageTitle('Add a new contact');
		// Clear data
		setData({
			_id: '',
			name: { first: '', middle: '', last: '' },
			dob: '',
			gender: 0,
			email: '',
			mobile: '',
			address: { street: '', city: '', postcode: 0, state: '', country: '' },
			relationship: 0,
			type: CONTACT_PERSON,
			associated: { business: [], person: [] },
			created: {
				date: '',
				by: '',
			},
		});

		dispatch(update({ state: STATE_DASHBOARD, _id: '', type: undefined }));
	}, []);

	const handleSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			const form = e.currentTarget;
			setSubmitLock(true);
			e.preventDefault();
			if (!form.checkValidity() || data === undefined) {
				const toastId = toast.open(
					<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Missing Fields!</p>
							<p>Please complete all required fields.</p>
						</div>
					</div>,
					5000
				);
			} else {
				if (data._id) {
					await Contacts.put(data);
					const toastId = toast.open(toastPostSuccessManageLink(data, dispatch), 8000);
				} else {
					const response = await Contacts.post(data);
					if (response._id) {
						data._id = response._id;
					}
					const toastId = toast.open(toastPostSuccessManageLink(data, dispatch), 8000);
				}
			}
			setSubmitLock(false);
			form.classList.add('was-validated');
		},
		[data, dispatch]
	);

	const renderSwitch = useCallback(
		(param: string) => {
			switch (param) {
				case 'work':
					return <EditWork dataState={[data, setData]} />;
				case 'projects':
					return <EditProjects dataState={[data, setData]} />;
				case 'notes':
					return <EditNotes dataState={[data, setData]} />;
				case 'tags':
					return <EditTags dataState={[data, setData]} />;
				// Always default back to the 'details' tab
				case '':
				case 'default':
				case 'details':
				default:
					return <EditDetails dataState={[data, setData]} />;
			}
		},
		[data, setData]
	);

	return (
		<Modal
			className="w-[93%] max-w-[93%] h-[100dvh] max-h-[92dvh]"
			// show={true}
			show={viewState.type == CONTACT_PERSON && (viewState.state === STATE_ADD || viewState.state === STATE_EDIT) ? true : false}
			title={pageTitle}
			onClose={handleClose}
			btn1={handleClose}
			margin
		>
			<div className="margin">
				<p className=" font-bold">Contact Settings</p>
				<ul>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('details')}
						>
							Personal details
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('work')}
						>
							Working status
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('projects')}
						>
							Projects
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('notes')}
						>
							Notes
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('tags')}
						>
							Tags
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_security')}
						>
							Invoices
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_security')}
						>
							Custom fields
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_security')}
						>
							Membership Portal
						</button>
					</li>
					<li>
						<button
							type="button"
							className="no-btn w-full text-left p-1 hover:bg-slate-300"
							onClick={() => setCurrentTab('user_')}
						>
							Notification Preferences
						</button>
					</li>
				</ul>
			</div>
			<div className="p-6 w-full dark:bg-dark-600">
				<form noValidate onSubmit={handleSubmit}>
					<div className="w-full">{renderSwitch(currentTab)}</div>
					<div className="absolute bottom-0 w-full">
						<button type="submit" className=" bg-emerald-500 hover:bg-emerald-600" disabled={submitLock}>
							{submitLock ? 'Loading...' : 'Save'}
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default ContactEdit;
