import { useEffect, useState } from 'react';
import { SETTINGS_STATE_USER } from '../../functions/Constants';
import { Settings } from './components/user';
// import Settings from './account';
import useDarkMode from '../../hooks/UseDarkMode';
import { FaMoon, FaSun } from 'react-icons/fa';
// import { debugManual } from '../../functions/CommonFunctions';
import { BsFillBuildingFill, BsFillPersonFill } from 'react-icons/bs';
import Users, { User } from '../../api/Users';

const SettingsPage: React.FC = () => {
	const [currentState, setCurrentState] = useState<number>(1);
	const [settingsData, setSettingsData] = useState<User>({
		_id: '',
		name: { first: '', middle: '', last: '' },
		username: '',
		dob: '',
		gender: 0,
		email: '',
		phone: '',
		mobile: '',
		address: { street: '', city: '', postcode: 0, state: '', country: '' },
		settings: {},
	});

	// We need the settings page to re-render when the settings modal has been updated
	useEffect(() => {
		async function fetchObject() {
			await Users.current(setSettingsData);
		}
		fetchObject();
	}, []);

	const handleUserAction = (type: number = 0, action: number, id: number) => {
		setCurrentState(action);
	};

	const ThemeIcon = () => {
		const [darkTheme, setDarkTheme] = useDarkMode();
		const handleMode = () => setDarkTheme(!darkTheme);
		return (
			<span onClick={handleMode}>
				{darkTheme ? <FaSun size="24" className="top-navigation-icon" /> : <FaMoon size="24" className="top-navigation-icon" />}
			</span>
		);
	};

	return (
		<>
			<div className="flex flex-col">
				<div className=" w-full flex relative flex-wrap border-2 border-stone-100 my-2 rounded-xl">
					<div className="w-1/4 p-2">
						<div className=" items-center text-center h-80 py-12 px-8">
							<BsFillPersonFill size={128} className=" m-auto rounded-xl border-2 border-slate-100" />
							{/* TODO - Allow profile picture uploads */}
							<input data-ref="image" type="file" accept="image/*" className=" hidden" />
							<p>
								{settingsData && settingsData.name && settingsData.name.first}
								{settingsData && settingsData.name && ' ' + settingsData.name.middle}
								{settingsData && settingsData.name && ' ' + settingsData.name.last}
							</p>
							<p className=" text-dark-600">IT Professional</p>
							<p className="bg-stone-500 text-white rouneded-lg">Administrator</p>
							<p className="p-2 text-red-500 font-serif">Your password is 348 days old!</p>{' '}
							{/* TODO - Reminders to change account passwords */}
						</div>
					</div>
					<div className="w-1/4 absolute right-0 my-12">
						<div className=" my-2">
							<label className=" font-bold">Email</label>
							<p>{(settingsData && settingsData.email && settingsData.email) || 'N/A'}</p>
						</div>
						<div className=" my-2">
							<label className=" font-bold">Phone</label>
							<p>{(settingsData && settingsData.phone && settingsData.phone) || 'N/A'}</p>
						</div>
						<div className=" my-2">
							<label className=" font-bold">Mobile</label>
							<p>{(settingsData && settingsData.mobile && settingsData.mobile) || 'N/A'}</p>
						</div>
						<div className=" my-2">
							<label className=" font-bold">Location</label>
							<p>
								{settingsData && settingsData.address?.state && settingsData.address.state}
								{settingsData &&
									settingsData.address?.country &&
									(settingsData && settingsData.address?.state && ', ') + settingsData.address.country}
							</p>
						</div>
					</div>
				</div>
				<div className=" w-full flex relative flex-wrap border-2 border-stone-100 my-2 rounded-xl">
					<div className="w-1/4 p-2">
						<div className=" items-center text-center h-80 py-12 px-8">
							<BsFillBuildingFill size={128} className=" m-auto rounded-xl border-2 border-slate-100" />
							<p>Byte Sized Academy</p>
							<p className=" text-dark-600">Education services</p>
						</div>
					</div>
					<div className="w-1/4 absolute right-0 my-12">
						<div className=" my-2">
							<label className=" font-bold">Email</label>
							<p>admin@bytesizedacademy.com.au</p>
						</div>
						<div className=" my-2">
							<label className=" font-bold">Phone</label>
							<p>0426662102</p>
						</div>
						<div className=" my-2">
							<label className=" font-bold">Mobile</label>
							<p>0426662102</p>
						</div>
						<div className=" my-2">
							<label className=" font-bold">Location</label>
							<p>QLD, Australia</p>
						</div>
					</div>
				</div>
				<div className="w-screen p-2">
					{/* <button className="" onClick={() => setCurrentState(SETTINGS_STATE_USER)}>
						User
					</button>
					&nbsp;
					<button className="" onClick={() => setCurrentState(SETTINGS_STATE_ACCOUNT)}>
						Account
					</button> */}
					{/* <button onClick={() => debugManual()}>DEBUG</button> */}
					<ThemeIcon />
					{currentState === SETTINGS_STATE_USER && <Settings />}
					{/* {currentState === SETTINGS_STATE_ACCOUNT && <SettingsAccount alertProps={() => handleAlert} />} */}
					{/* {currentState === STATE_ADD && (currentType === 1 ? <ContactEdit contact_id={currentID} alertProps={handleAlert} action={handleContactAction} /> : <BusinessEdit business_id={currentID} alertProps={handleAlert} action={handleContactAction} />)} */}
				</div>
			</div>
		</>
	);
};

export default SettingsPage;
