import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import { _id, API_ENDPOINT, DataState, serverURI, STATE_DASHBOARD, STATE_EDIT } from '../../functions/Constants';
import { RootState } from '../../state/store';
import { update } from '../../state/viewSlice';
import { useState, useEffect, useCallback, useRef } from 'react';
import { getAllContactsArray, getAllUsersArray } from '../../functions/CommonFunctions';
import handleOnChange from '../../functions/handleOnChange';
import Users from '../../api/Users';
import { useToast } from '../../components/toast/ToastService';
import axios from 'axios';
import { formatMongooseDate } from '../../pages/projects/edit';

// import 'grapesjs/dist/css/grapes.min.css';
import grapesjs, { Editor } from 'grapesjs';

const SingleColSVG = `<svg viewBox="0 0 24 24" height="5em" width="5em">
        <path fill="currentColor" d="M2 20h20V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Z"></path>
      </svg>`;

const DoubleColSVG = `<svg viewBox="0 0 23 24" height="5em" width="5em">
        <path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"></path>
      </svg>`;

const TrippleColSVG = `<svg viewBox="0 0 23 24" height="5em" width="5em">
        <path fill="currentColor" d="M2 20h4V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM17 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1ZM9.5 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1Z"></path>
      </svg>`;

const OddColSVG = `<svg viewBox="0 0 24 24" height="5em" width="5em">
        <path fill="currentColor" d="M2 20h5V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM10 20h12V4H10v16Zm-1 0V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1Z"></path>
      </svg>`;

const BsCursorText = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="5em" width="5em" xmlns="http://www.w3.org/2000/svg"><path d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.17 4.17 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.5 3.5 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.17 4.17 0 0 1-2.06-.566A5 5 0 0 1 8 13.65a5 5 0 0 1-.44.285 4.17 4.17 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.5 3.5 0 0 0-.436-.294A3.17 3.17 0 0 0 5.5 2.5.5.5 0 0 1 5 2m2.648 10.645"></path></svg>`;
const BsCardImage = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="5em" width="5em" xmlns="http://www.w3.org/2000/svg"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"></path><path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"></path></svg>`;
const BsYoutube = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="5em" width="5em" xmlns="http://www.w3.org/2000/svg"><path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"></path></svg>`;
const BsSquareFill = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"></path></svg>`;
const BsMapFill = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="5em" width="5em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.5.5 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.5.5 0 0 0-.196 0zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1z"></path></svg>`;
const FormSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="5em" width="5em"><path d="M22 5.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 8H3V6h18v2zM22 10.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 13H3v-2h18v2z"></path><rect width="10" height="3" x="2" y="15" rx=".5"></rect></svg>`;
const InputSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="5em" width="5em"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"></path><path d="M4 10h1v4H4z"></path></svg>`;
const TextareaSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="5em" width="5em"><path d="M22 7.5c0-.9-.5-1.5-1.3-1.5H3.4C2.5 6 2 6.6 2 7.5v9c0 .9.5 1.5 1.3 1.5h17.4c.8 0 1.3-.6 1.3-1.5v-9zM21 17H3V7h18v10z"></path><path d="M4 8h1v4H4zM19 7h1v10h-1zM20 8h1v1h-1zM20 15h1v1h-1z"></path></svg>`;
const SelectSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="5em" width="5em"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"></path><path d="M18.5 13l1.5-2h-3zM4 11.5h11v1H4z"></path></svg>`;
const CheckboxSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="5em" width="5em"><path d="M10 17l-5-5 1.41-1.42L10 14.17l7.59-7.59L19 8m0-5H5c-1.11 0-2 .89-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2z"></path></svg>`;
const RadioSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="5em" width="5em"><path d="M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8m0-18C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 5c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z"></path></svg>`;
const CountdownSVG = `<svg viewBox="0 0 24 24" height="5em" width="5em">
        <path fill="currentColor" d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 11.5V13H11V7H12.5V11.5H17Z"></path>
      </svg>`;
const TooltipSVG = `<svg viewBox="0 0 24 24" height="5em" width="5em">
          <path d="M4 2h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-4l-4 4-4-4H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2m0 2v12h4.83L12 19.17 15.17 16H20V4H4z"></path>
        </svg>`;
const CodeSVG = `<svg viewBox="0 0 24 24" height="5em" width="5em">
        <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
      </svg>`;
const DividerSVG = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="5em" width="5em" xmlns="http://www.w3.org/2000/svg"><path d="M2 12.75a.75.75 0 0 1 .75-.75h18.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z"></path></svg>`;

const BsFillPersonVcardFill = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="5em" width="5em" xmlns="http://www.w3.org/2000/svg"><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8m1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5m-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96q.04-.245.04-.5M7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0"></path></svg>`;
const PhoneSVG = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="2rem" width="2rem" xmlns="http://www.w3.org/2000/svg"><path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"></path><path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"></path></svg>`;
const DesktopSVG = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" aria-hidden="true" height="2rem" width="2rem" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z" clip-rule="evenodd"></path></svg>`;
const LaptopSVG = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="2rem" width="2rem" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 18c1.1 0 1.99-.9 1.99-2L22 5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2H0c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2h-4zM4 5h16v11H4V5zm8 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>`;

const isFormEvent = (e: any): e is React.FormEvent<HTMLFormElement> => {
	return e && e.currentTarget && e.currentTarget.tagName === 'FORM';
};

export default () => {
	// const viewState = useSelector((state: RootState) => state.example.value);
	// const dispatch = useDispatch();
	// const [selectedTeam, setselectedTeam] = useState<_id[] | undefined>();
	// const [submitLock, setSubmitLock] = useState<boolean>(false);
	const toast = useToast();
	// const [data, setData] = useState<{
	// 	atendees: _id[];
	// 	team: _id[];
	// 	type?: _id;
	// 	title: string;
	// 	description?: string;
	// 	location: _id;
	// 	duration?: number;
	// 	note?: string;
	// 	time: {
	// 		start: Date | string;
	// 		end: Date | string;
	// 	};
	// }>({
	// 	atendees: [],
	// 	team: [],
	// 	type: '30',
	// 	title: '30-Minute Introduction',
	// 	description: '',
	// 	location: 'Google Meet',
	// 	duration: 30,
	// 	time: {
	// 		start: new Date(),
	// 		end: new Date(),
	// 	},
	// });
	const editorRef = useRef<Editor | null>(null); // reference to track initialization

	useEffect(() => {
		if (!editorRef.current) {
			const editor = grapesjs.init({
				// Indicate where to init the editor. You can also pass an HTMLElement
				container: '#gjs',
				// Get the content for the canvas directly from the element
				// As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
				fromElement: true,
				// Size of the editor
				height: '100dvh',
				width: 'auto',
				// Disable the storage manager for the moment
				storageManager: {
					type: 'remote',
					// ...
					stepsBeforeSave: 10,
					options: {
						remote: {
							headers: {}, // Custom headers for the remote storage request
							urlStore: 'https://node.draeockenden.com.au/api/gjs/store', // Endpoint URL where to store data project
							urlLoad: 'https://node.draeockenden.com.au/api/gjs/load', // Endpoint URL where to load data project
						},
					},
				},
				// Avoid any default panel

				layerManager: {
					appendTo: '.layers-container',
				},
				mediaCondition: 'min-width', // default is `max-width`

				deviceManager: {
					devices: [
						{
							name: 'Desktop',
							width: '', // default size
						},
						{
							name: 'Laptop',
							width: '720px', // this value will be used on canvas width
							widthMedia: '800px', // this value will be used in CSS @media
						},
						{
							name: 'Mobile',
							width: '320px', // this value will be used on canvas width
							widthMedia: '480px', // this value will be used in CSS @media
						},
					],
				},

				// We define a default panel as a sidebar to contain layers
				panels: {
					defaults: [
						// ...
						{
							id: 'layers',
							el: '.panel__right',
							// Make the panel resizable
							resizable: {
								maxDim: 350,
								minDim: 200,
								tc: false, // Top handler
								cl: true, // Left handler
								cr: false, // Right handler
								bc: false, // Bottom handler
								// Being a flex child we need to change `flex-basis` property
								// instead of the `width` (default)
								keyWidth: 'flex-basis',
							},
						},
						{
							id: 'panel-switcher',
							el: '.panel__switcher',
							// Make the panel resizable
							resizable: {
								maxDim: 350,
								minDim: 200,
								tc: false, // Top handler
								cl: true, // Left handler
								cr: false, // Right handler
								bc: false, // Bottom handler
								// Being a flex child we need to change `flex-basis` property
								// instead of the `width` (default)
								keyWidth: 'flex-basis',
							},
							buttons: [
								{
									id: 'show-blocks',
									active: true,
									label: 'Blocks',
									command: 'show-blocks',
									togglable: false,
								},
								{
									id: 'show-style',
									active: true,
									label: 'Styles',
									command: 'show-styles',
									togglable: false,
								},
								{
									id: 'show-traits',
									active: true,
									label: 'Traits',
									command: 'show-traits',
									togglable: false,
								},
								{
									id: 'show-layers',
									active: true,
									label: 'Layers',
									command: 'show-layers',
									// Once activated disable the possibility to turn it off
									togglable: false,
								},
							],
						},
						{
							id: 'panel-devices',
							el: '.panel__devices',
							buttons: [
								{
									id: 'device-desktop',
									label: DesktopSVG,
									command: 'set-device-desktop',
									active: true,
									togglable: false,
								},
								{
									id: 'device-laptop',
									label: LaptopSVG,
									command: 'set-device-laptop',
									togglable: false,
								},
								{
									id: 'device-mobile',
									label: PhoneSVG,
									command: 'set-device-mobile',
									togglable: false,
								},
							],
						},
					],
				},
				// The Selector Manager allows to assign classes and
				// different states (eg. :hover) on components.
				// Generally, it's used in conjunction with Style Manager
				// but it's not mandatory
				selectorManager: {
					appendTo: '.styles-container',
				},
				styleManager: {
					appendTo: '.styles-container',
					// sectors: [
					// 	{
					// 		name: 'Dimension',
					// 		open: false,
					// 		// Use built-in properties
					// 		buildProps: ['width', 'min-height', 'padding'],
					// 		// Use `properties` to define/override single property
					// 		properties: [
					// 			{
					// 				// Type of the input,
					// 				// options: integer | radio | select | color | slider | file | composite | stack
					// 				type: 'integer',
					// 				name: 'The width', // Label for the property
					// 				property: 'width', // CSS property (if buildProps contains it will be extended)
					// 				units: ['px', '%'], // Units, available only for 'integer' types
					// 				defaults: 'auto', // Default value
					// 				min: 0, // Min value, available only for 'integer' types
					// 			},
					// 		],
					// 	},
					// 	{
					// 		name: 'Extra',
					// 		open: false,
					// 		buildProps: ['background-color', 'box-shadow', 'custom-prop'],
					// 		properties: [
					// 			{
					// 				id: 'custom-prop',
					// 				name: 'Custom Label',
					// 				property: 'font-size',
					// 				type: 'select',
					// 				defaults: '32px',
					// 				// List of options, available only for 'select' and 'radio'  types
					// 				options: [
					// 					{ value: '12px', name: 'Tiny', id: '1' },
					// 					{ value: '18px', name: 'Medium', id: '2' },
					// 					{ value: '32px', name: 'Big', id: '3' },
					// 				],
					// 			},
					// 		],
					// 	},
					// ],
				},

				traitManager: {
					appendTo: '.traits-container',
				},
				blockManager: {
					appendTo: '#blocks',
					blocks: [
						{
							id: 'section', // id is mandatory
							label: '<b>Section</b>', // You can use HTML/SVG inside labels
							category: 'Basic',
							attributes: { class: 'gjs-block-section' },
							content: `<section>
								<h1>This is a simple title</h1>
								<div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
								</section>`,
						},
						{
							id: 'divider', // id is mandatory
							label: '<b>Divider</b>', // You can use HTML/SVG inside labels
							media: DividerSVG,
							category: 'Basic',
							// attributes: { class: 'gjs-block-section' },
							content: `<hr />`,
						},
						{
							id: 'text',
							label: 'Text',
							media: BsCursorText,
							category: 'Basic',
							content: '<div data-gjs-type="text">Insert your text here</div>',
						},
						{
							id: 'image',
							label: 'Image',
							category: 'Basic',
							media: BsCardImage,
							// Select the component once it's dropped
							select: true,
							// You can pass components as a JSON instead of a simple HTML string,
							// in this case we also use a defined component type `image`
							content: { type: 'image' },
							// This triggers `active` event on dropped components and the `image`
							// reacts by opening the AssetManager
							activate: true,
						},
						{
							id: '1-cols',
							label: '1 Cols',
							media: SingleColSVG,
							category: 'Grid',
							// droppable: true,
							// Select the component once it's dropped
							select: true,
							// You can pass components as a JSON instead of a simple HTML string,
							content: `
								<div style="
									padding: 10px;
									min-height: 75px;" data-gjs-droppable='true' data-gjs-highlightable='true' data-gjs-custom-name="Row" data-gjs-draggable="true" data-gjs-contenteditable="true">
								</div>
							`,
						},
						{
							id: '2-cols',
							label: '2 Cols',
							category: 'Grid',
							media: DoubleColSVG,
							// Select the component once it's dropped
							select: true,
							// You can pass components as a JSON instead of a simple HTML string,
							content: `
								<div style="display: flex;
									justify-content: flex-start;
									align-items: stretch;
									flex-wrap: nowrap;
									padding: 10px;
									min-height: 75px;" data-gjs-custom-name="Row" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'>
									<div style="flex-grow: 1;
									flex-basis: 100%;
									padding: 5px;" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'></div>
									<div style="flex-grow: 1;
									flex-basis: 100%;
									padding: 5px;" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'></div>
								</div>
							`,
						},
						{
							id: '3-cols',
							label: '3 Cols',
							category: 'Grid',
							media: TrippleColSVG,
							// Select the component once it's dropped
							select: true,
							content: `
								<div style="display: flex;
									justify-content: flex-start;
									align-items: stretch;
									flex-wrap: nowrap;
									padding: 10px;
									min-height: 75px;" data-gjs-custom-name="Row" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'>
									<div style="flex-grow: 1;
									flex-basis: 100%;
									padding: 5px;" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'></div>
									<div style="flex-grow: 1;
									flex-basis: 100%;
									padding: 5px;" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'></div>
									<div style="flex-grow: 1;
									flex-basis: 100%;
									padding: 5px;" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'></div>
								</div>
							`,
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							// activate: true,
						},
						{
							id: 'odd-cols',
							label: '2 Cols (2/7)',
							category: 'Grid',
							media: OddColSVG,
							// Select the component once it's dropped
							select: true,
							content: `
								<div style="display: flex;
									justify-content: flex-start;
									align-items: stretch;
									flex-wrap: nowrap;
									padding: 10px;
									min-height: 75px;" data-gjs-custom-name="Row" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'>
									<div style="flex-grow: 1;
									flex-basis: 100%;
									padding: 5px;" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'></div>
									<div style="flex-grow: 1;
									flex-basis: 100%;
									padding: 5px;" data-gjs-draggable="true" data-gjs-droppable='true' data-gjs-highlightable='true'></div>
								</div>
							`,
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							// activate: true,
						},
						{
							id: 'video',
							label: 'Video',
							category: 'Basic',
							media: BsYoutube,
							// Select the component once it's dropped
							select: true,
							content: { type: 'video' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'map',
							label: 'map',
							media: BsMapFill,
							category: 'Basic',
							// Select the component once it's dropped
							select: true,
							content: { type: 'map' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'form',
							label: 'form',
							media: FormSVG,
							category: 'Forms',
							// Select the component once it's dropped
							select: true,
							content: { type: 'form' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'input',
							label: 'input',
							media: InputSVG,
							category: 'Forms',
							// Select the component once it's dropped
							select: true,
							content: { type: 'input' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'textarea',
							label: 'Textxarea',
							media: TextareaSVG,
							category: 'Forms',
							// Select the component once it's dropped
							select: true,
							content: { type: 'textarea' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'select',
							label: 'Select',
							media: SelectSVG,
							category: 'Forms',
							// Select the component once it's dropped
							select: true,
							content: { type: 'select' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'button',
							label: '<b>Button</b>', // Label displayed in the blocks panel
							media: BsSquareFill,
							category: 'Basic',
							attributes: { class: 'fa fa-hand-pointer' }, // Icon to represent the button
							content: {
								type: 'button',
								// content: 'Click me', // Default text inside the button
								traits: [
									{
										type: 'text',
										label: 'Text',
										name: 'content',
									},
									{
										type: 'color',
										label: 'Background Color',
										name: 'backgroundColor',
									},
									{
										type: 'select',
										label: 'Size',
										name: 'size',
										options: [
											{ id: 'small', name: 'Small' },
											{ id: 'medium', name: 'Medium' },
											{ id: 'large', name: 'Large' },
										],
									},
								],
							},
						},
						{
							id: 'card',
							label: 'Card',
							media: BsFillPersonVcardFill,
							category: 'Templates',
							// Select the component once it's dropped
							select: true,
							content: {
								components: [
									{
										tagName: 'card',
										components: [
											{ type: 'image', style: { margin: '' } },
											{ type: 'text', content: 'Alice Heatherington' },
											{ type: 'text', content: 'Founder, CEO', style: { color: '#888' } },
											{
												type: 'text',
												content: 'This is just a Lorem text: Lorem ipsum dolor sit amet',
												style: { height: '50px' },
											},
										],
										style: { padding: '5px' },
									},
								],
							},
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							// activate: true,
						},
						{
							id: 'label',
							label: 'Label',
							category: 'Forms',
							// Select the component once it's dropped
							select: true,
							content: { type: 'label', content: 'Label' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'checkbox',
							label: 'Checkbox',
							media: CheckboxSVG,
							category: 'Forms',
							// Select the component once it's dropped
							select: true,
							content: { type: 'checkbox' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'radio',
							label: 'Radio',
							media: RadioSVG,
							category: 'Forms',
							// Select the component once it's dropped
							select: true,
							content: { type: 'radio' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'countdown',
							label: 'Countdown',
							media: CountdownSVG,
							category: 'Extras',
							// Select the component once it's dropped
							select: true,
							content: { type: 'countdown' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'tabs',
							label: 'Tabs',
							category: 'Extras',
							// Select the component once it's dropped
							select: true,
							content: { type: 'tabs' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'code',
							label: 'Custom Code',
							media: CodeSVG,
							category: 'Extras',
							// Select the component once it's dropped
							select: true,
							content: { type: 'code' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
						{
							id: 'tooltip',
							label: 'Tooltip',
							media: TooltipSVG,
							category: 'Extras',
							// Select the component once it's dropped
							select: true,
							content: { type: 'tooltip' },
							// // This triggers `active` event on dropped components and the `image`
							// // reacts by opening the AssetManager
							activate: true,
						},
					],
				},
			});

			// editor.Components.addType('comp-with-js', {
			// 	model: {
			// 	  defaults: {
			// 		script,
			// 		// Define default values for your custom properties
			// 		myprop1: 'value1',
			// 		myprop2: '10',
			// 		// Define traits, in order to change your properties
			// 		traits: [
			// 		  {
			// 			type: 'select',
			// 			name: 'myprop1',
			// 			changeProp: true,
			// 			options: [
			// 			  { value: 'value1', name: 'Value 1' },
			// 			  { value: 'value2', name: 'Value 2' },
			// 			],
			// 		  },
			// 		  {
			// 			type: 'number',
			// 			name: 'myprop2',
			// 			changeProp: true,
			// 		  },
			// 		],
			// 		// Define which properties to pass (this will also reset your script on their changes)
			// 		'script-props': ['myprop1', 'myprop2'],
			// 		// ...
			// 	  },
			// 	},
			//   });

			editor.Components.addType('button', {
				model: {
					defaults: {
						name: '', // Simple custom name
						draggable: true, // Add `draggable` logic
						droppable: true,
						resizable: true,
						components: [
							{
								tagName: 'button',
								components: [{ type: 'text', content: 'Click me' }],
								style: {
									padding: '10px 20px',
									backgroundColor: '#007bff',
									color: '#353535',
									border: 'none',
									borderRadius: '5px',
									cursor: 'pointer',
								},
							},
						],
					},
				},
			});
			editor.Components.addType('checkbox', {
				model: {
					defaults: {
						name: 'Input radio', // Simple custom name
						draggable: true, // Add `draggable` logic
						droppable: true,
						resizable: true,
						components: [
							{
								tagName: 'input',
								attributes: { type: 'checkbox' },
								content: '',
							},
						],
					},
				},
			});
			editor.Components.addType('radio', {
				model: {
					defaults: {
						name: 'Input radio', // Simple custom name
						draggable: true, // Add `draggable` logic
						droppable: true,
						resizable: true,
						components: [
							{
								tagName: 'input',
								attributes: { type: 'radio' },
								content: '',
							},
						],
					},
				},
			});
			editor.Components.addType('select', {
				model: {
					defaults: {
						name: 'select', // Simple custom name
						draggable: true, // Add `draggable` logic
						droppable: true,
						resizable: true,
						components: [
							{
								tagName: 'select',
								content: '',
							},
						],
					},
				},
			});
			editor.Components.addType('input', {
				model: {
					defaults: {
						name: 'Input', // Simple custom name
						draggable: true, // Add `draggable` logic
						droppable: true,
						resizable: true,
						components: [
							{
								tagName: 'input',
								content: '',
							},
						],
					},
				},
			});
			editor.Components.addType('textarea', {
				model: {
					defaults: {
						name: 'textarea', // Simple custom name
						draggable: true, // Add `draggable` logic
						droppable: true,
						editable: true,
						// tagName: 'text',
						style: { height: '50px' },
						type: 'text',
						components: 'This is just a Lorem text: Lorem ipsum dolor sit amet',
						// components: [
						// 	{
						// 		type: 'text',

						// 	},
						// ],
					},
				},
			});

			editor.Components.addType('card', {
				model: {
					defaults: {
						name: 'div', // Simple custom name
						draggable: true, // Add `draggable` logic
						droppable: true,
						editable: true,
						components: [
							{
								tagName: 'div',
								components: [
									{ type: 'image', style: { margin: '' } },
									{ type: 'text', content: 'Alice Heatherington' },
									{ type: 'text', content: 'Founder, CEO', style: { color: '#888' } },
									{
										type: 'text',
										content: 'This is just a Lorem text: Lorem ipsum dolor sit amet',
										style: { height: '50px' },
									},
								],
								style: { padding: '5px' },
							},
						],
					},
				},
			});

			// Define a new custom component
			editor.Components.addType('comp-with-js', {
				model: {
					defaults: {
						// script,
						// Add some style, just to make the component visible
						style: {
							width: '100px',
							height: '100px',
							background: 'red',
						},
					},
				},
			});

			// Create a block for the component, so we can drop it easily
			editor.Blocks.add('test-block', {
				label: 'Test block',
				attributes: { class: 'fa fa-text' },
				content: { type: 'comp-with-js' },
			});

			// editor.Blocks.add('button', {
			// 	label: 'EXAM',
			// 	id: 'exam',
			// 	// Select the component once it's dropped
			// 	// select: true,
			// 	content: `<button></button>`,
			// 	// content: {
			// 	// 	tagName: 'button',
			// 	// 	// draggable: false,
			// 	// 	attributes: { 'some-attribute': 'some-value' },
			// 	// 	components: [
			// 	// 		{
			// 	// 			tagName: 'span',
			// 	// 			content: '<b>Some static content</b>',
			// 	// 		},
			// 	// 		{
			// 	// 			tagName: 'div',
			// 	// 			// use `content` for static strings, `components` string will be parsed
			// 	// 			// and transformed in Components
			// 	// 			components: '<span>HTML at some point</span>',
			// 	// 		},
			// 	// 	],
			// 	// },
			// });

			editor.Panels.addPanel({
				id: 'panel-top',
				el: '.panel__top',
			});

			editor.Panels.addPanel({
				id: 'basic-actions',
				el: '.panel__basic-actions',
				buttons: [
					{
						id: 'visibility',
						active: true, // active by default
						className: 'btn-toggle-borders',
						label: '<u>B</u>',
						command: 'sw-visibility', // Built-in command
					},
					{
						id: 'export',
						className: 'btn-open-export',
						label: 'Exp',
						command: 'export-template',
						context: 'export-template', // For grouping context of buttons from the same panel
					},
					{
						id: 'show-json',
						className: 'btn-show-json',
						label: 'JSON',
						context: 'show-json',
						command(editor: {
							Modal: {
								setTitle: (arg0: string) => {
									(): any;
									new (): any;
									setContent: { (arg0: string): { (): any; new (): any; open: { (): void; new (): any } }; new (): any };
								};
							};
							getComponents: () => any;
						}) {
							editor.Modal.setTitle('Components JSON')
								.setContent(
									`<textarea style="width:100%; height: 250px;">
				  ${JSON.stringify(editor.getComponents())}
				</textarea>`
								)
								.open();
						},
					},
				],
			});

			editor.on('run:export-template:before', (opts) => {
				console.log('Before the command run');
				if (0 /* some condition */) {
					opts.abort = 1;
				}
			});
			editor.on('run:export-template', () => console.log('After the command run'));
			editor.on('abort:export-template', () => console.log('Command aborted'));

			// Define commands
			editor.Commands.add('show-layers', {
				getRowEl(editor: any) {
					return editor.getContainer().closest('.editor-row');
				},
				getLayersEl(row: any) {
					return row.querySelector('.layers-container');
				},

				run(editor, sender) {
					const lmEl = this.getLayersEl(this.getRowEl(editor));
					lmEl.style.display = '';
				},
				stop(editor, sender) {
					const lmEl = this.getLayersEl(this.getRowEl(editor));
					lmEl.style.display = 'none';
				},
			});
			editor.Commands.add('show-styles', {
				getRowEl(editor: any) {
					return editor.getContainer().closest('.editor-row');
				},
				getStyleEl(row: any) {
					return row.querySelector('.styles-container');
				},

				run(editor, sender) {
					const smEl = this.getStyleEl(this.getRowEl(editor));
					smEl.style.display = '';
				},
				stop(editor, sender) {
					const smEl = this.getStyleEl(this.getRowEl(editor));
					smEl.style.display = 'none';
				},
			});

			editor.Commands.add('show-traits', {
				getTraitsEl(editor: any) {
					const row = editor.getContainer().closest('.editor-row');
					return row.querySelector('.traits-container');
				},
				run(editor, sender) {
					this.getTraitsEl(editor).style.display = '';
				},
				stop(editor, sender) {
					this.getTraitsEl(editor).style.display = 'none';
				},
			});

			editor.Commands.add('show-blocks', {
				getTraitsEl(editor: any) {
					const row = editor.getContainer().closest('.editor-row');
					return row.querySelector('#blocks');
				},
				run(editor, sender) {
					this.getTraitsEl(editor).style.display = '';
				},
				stop(editor, sender) {
					this.getTraitsEl(editor).style.display = 'none';
				},
			});

			// Commands
			editor.Commands.add('set-device-desktop', {
				run: (editor) => editor.setDevice('Desktop'),
			});
			editor.Commands.add('set-device-laptop', {
				run: (editor) => editor.setDevice('Laptop'),
			});
			editor.Commands.add('set-device-mobile', {
				run: (editor) => editor.setDevice('Mobile'),
			});
			editor.on('change:device', () => console.log('Current device: ', editor.getDevice()));
			editor.setDevice('Mobile');

			// Keep reference to editor instance
			editorRef.current = editor;
		}
	}, []);

	const handleSend = useCallback(async () => {
		axios.post(
			`${serverURI}mailer/gjs`,
			// { body: editorRef.current?.runCommand('gjs-get-inlined-html') },
			{
				body:
					'<!DOCTYPE html><html><head><style>' +
					editorRef.current?.getCss() +
					'</style></head>' +
					editorRef.current?.getHtml() +
					'</html>',
			},
			{ withCredentials: true }
		);
	}, []);

	return (
		// <Modal
		// 	className="w-3/4 max-w-[73%] h-[60dvh] max-h-[80dvh]"
		// 	show={true}
		// 	// show={viewState.state === 8 ? true : false}
		// 	title={'Schedule an appointment'}
		// 	onClose={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
		// 	btn1={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
		// >
		<div className="w-full dark:bg-dark-600 gjs">
			{/* <form noValidate> */}
			<div className="panel__top">
				<div className="panel__basic-actions">
					<button className="" onClick={handleSend}>
						Send
					</button>
				</div>
				<div className="panel__devices"></div>
				<div className="panel__switcher"></div>
			</div>
			<div className="editor-row">
				<div className="editor-canvas">
					<div id="gjs"></div>
				</div>
				<div className="panel__right">
					<div className="layers-container"></div>
					<div className="styles-container"></div>
					<div className="traits-container"></div>
					<div id="blocks"></div>
				</div>
			</div>
			{/* </form> */}
			{/* {viewState.state === 9 && (
				<button className="bg-red-500 hover:bg-red-600" onClick={() => dispatch(update({ state: 0, _id: '' }))}>
					Close Builder
				</button>
			)} */}
		</div>
		// </Modal>
	);
};
