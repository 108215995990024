// DEBUG MODE
export const DEBUG_MODE = true; // prevents emails from being sent

// API
export const API_ENDPOINT = 'https://node.draeockenden.com.au/api';

// Contact Editing
export const STATE_DASHBOARD = 0;
export const STATE_MANAGE = 1;
export const STATE_ADD = 2;
export const STATE_EDIT = 3;

export const CONTACT_PERSON = 1;
export const CONTACT_BUSINESS = 2;

// User settings Editing
export const SETTINGS_STATE_USER = 1;
export const SETTINGS_STATE_ACCOUNT = 2;

// Error codes
export const ERROR_CODE_403 = 'Access to this rescource has been denied.';
export const ERROR_CODE_404 = 'This resource cannot be found.';

export const authResultSuccess = 0;
export const authResultInvalidUsername = 1;
export const authResultInvalidPassword = 2;
export const authResultServerError = 10;

export const serverURI = 'https://node.draeockenden.com.au/api/';
export const appURI = 'https://dev.draeockenden.com.au/';

export type DataState<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export interface ContactEditProps {
	contact_id?: _id;
}

export interface BusinessEditProps {
	business_id?: _id;
}

export interface ContactTableProps {}

export interface AlertProps {
	response: (visibility: boolean, style: string, text: string) => void;
}

export type IndexedArray = {
	[key: number | string]: string | number | boolean | undefined;
};

// Declare `_id` to avoid confusion with data type
export type _id = string;
