import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';
import { _id, API_ENDPOINT, DataState, STATE_DASHBOARD, STATE_EDIT } from '../../functions/Constants';
import { RootState } from '../../state/store';
import { update } from '../../state/viewSlice';
import { Select, SelectOption } from '../../components/Select';
import SearchSelect from '../../components/Combobox';
import { useState, useEffect, useCallback } from 'react';
import { getAllContactsArray, getAllUsersArray } from '../../functions/CommonFunctions';
import handleOnChange from '../../functions/handleOnChange';
import Users from '../../api/Users';
import { useToast } from '../../components/toast/ToastService';
import axios from 'axios';
import { formatMongooseDate } from '../../pages/projects/edit';

const isFormEvent = (e: any): e is React.FormEvent<HTMLFormElement> => {
	return e && e.currentTarget && e.currentTarget.tagName === 'FORM';
};

// function formatMongooseDate(mongooseDate?: string | Date) {
// 	if (!mongooseDate) return;
// 	const date = new Date(mongooseDate); // Converts the Mongoose date to a JavaScript Date object

// 	// Format to YYYY-MM-DD
// 	const year = date.getFullYear();
// 	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
// 	const day = String(date.getDate()).padStart(2, '0');

// 	return `${year}-${month}-${day} 00:00:00`;
// }

export default () => {
	const viewState = useSelector((state: RootState) => state.example.value);
	const dispatch = useDispatch();
	const [usersList, setUsersList] = useState<SelectOption[] | undefined>();
	const [guestList, setGuestList] = useState<SelectOption[] | undefined>();
	const [selectedTeam, setselectedTeam] = useState<_id[] | undefined>();
	const [submitLock, setSubmitLock] = useState<boolean>(false);
	const toast = useToast();
	const [data, setData] = useState<{
		atendees: _id[];
		team: _id[];
		type?: _id;
		title: string;
		description?: string;
		location: _id;
		duration?: number;
		note?: string;
		time: {
			start: Date | string;
			end: Date | string;
		};
	}>({
		atendees: [],
		team: [],
		type: '30',
		title: '30-Minute Introduction',
		description: '',
		location: 'Google Meet',
		duration: 30,
		time: {
			start: new Date(),
			end: new Date(),
		},
	});

	useEffect(() => {
		async function fetchObjects() {
			// TODO - users list
			const guestOptions = await getAllContactsArray(undefined);
			setGuestList(guestOptions);
			// TODO - users list
			const usersOptions = await getAllUsersArray(undefined);
			setUsersList(usersOptions);
		}
		fetchObjects();
	}, []);

	// Helper function to calculate the end time based on start time and duration
	const calculateEndTime = (start: Date | string, duration: number) => {
		const startTime = new Date(start);
		return new Date(startTime.getTime() + duration * 60000); // duration in minutes
	};

	const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const startTime = e.target.value;
		setData((prevData) => ({
			...prevData,
			time: {
				start: startTime,
				end: calculateEndTime(startTime, prevData.duration || 30),
			},
		}));
	};

	const handleDurationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const duration = Number(e.target.value);
		setData((prevData) => ({
			...prevData,
			duration,
			time: {
				...prevData.time,
				end: calculateEndTime(prevData.time.start, duration),
			},
		}));
	};

	// Set default atendee
	useEffect(() => {
		// if (viewState._id) {
		// 	Users.current((e) => {
		// 		var val = e;
		// 		var guests: [] | _id[] = [];
		// 		const viewState = useSelector((state: RootState) => state.example.value);
		// 		if (viewState._id) {
		// 			guests = [viewState._id];
		// 		}
		// 		setData({
		// 			...data,
		// 			team: [val],
		// 			atendees: guests,
		// 		});
		// 	});
		// }
		if (viewState._id) {
			setData({
				...data,
				atendees: [viewState._id],
				// team: currentId,
			});
		}
	}, [viewState._id]);

	const handleSubmit = useCallback(async () => {
		setSubmitLock(true);
		if (data.title === '') {
			const toastId = toast.open(
				<div className=" flex gap-2 bg-red-300 text-red-500 p-4 rounded-lg shadow-lg">
					<div></div>
					<div>
						<p>Missing Fields!</p>
						<p>Please complete all required fields.</p>
					</div>
				</div>,
				5000
			);
		} else {
			const response = await axios.post(`${API_ENDPOINT}/gcal/create-event`, { ...data }, { withCredentials: true });
			// const response = await Contacts.notes.put(contact._id, data);
			// if (response && response._id) {
			// 	const toastId = toast.open(
			// 		<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
			// 			<div></div>
			// 			<div>
			// 				<p>Note updated!</p>
			// 			</div>
			// 		</div>,
			// 		8000
			// 	);
			// } else {
			// const response = await Contacts.notes.post(contact._id, data);
			if (response.status === 201) {
				const toastId = toast.open(
					<div className=" flex gap-2 bg-green-300 text-emerald-500 p-4 rounded-lg shadow-lg">
						<div></div>
						<div>
							<p>Note added!</p>
						</div>
					</div>,
					8000
				);
			}
			// }
		}
		setSubmitLock(false);
	}, [data]);

	return (
		<Modal
			className="w-1/2 max-w-[73%] h-[60dvh] max-h-[80dvh]"
			// show={true}
			show={viewState.state === 8 ? true : false}
			title={'Schedule an appointment'}
			onClose={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
			btn1={() => dispatch(update({ state: STATE_DASHBOARD, _id: '' }))}
		>
			<div className="p-6 w-full dark:bg-dark-600">
				<form noValidate>
					<div className=" bottom-0 w-full flex flex-col gap-4">
						<div className="flex flex-row gap-4">
							<div className="w-full inline-block">
								<label>Calendar</label>
								<Select
									value={'1'}
									onChange={(e) => {
										// handleOnChange(e, ['type'], setData);
									}}
									options={[
										{ _id: '1', label: "Drae Ockenden's Google Calendar" },
										// { _id: '15', label: '15 Min' },
										// { _id: '30', label: '30 Min' },
										// { _id: '45', label: '45 Min' },
										// { _id: '60', label: '1 Hr' },
									]}
								/>
							</div>
							<div className="w-1/2 inline-block">
								<label>Event Type</label>
								<Select
									value={data.type}
									onChange={(e) => {
										handleOnChange(e, ['type'], setData);
									}}
									options={[
										{ _id: '0', label: 'Manual' },
										{ _id: '15', label: '15 Min' },
										{ _id: '30', label: '30 Min' },
										{ _id: '45', label: '45 Min' },
										{ _id: '60', label: '1 Hr' },
									]}
								/>
							</div>
						</div>
						<div className="flex gap-4">
							<div className="w-full ">
								<label>Event Title</label>
								<input
									type="text"
									id="title"
									name="title"
									defaultValue={data.title}
									onChange={(e) => {
										handleOnChange(e, ['title'], setData);
									}}
								/>
							</div>
							<div className="w-1/2">
								<label>Location</label>
								<Select
									value={data.location}
									onChange={(e) => {
										handleOnChange(e, ['location'], setData);
									}}
									options={[
										{ _id: 'Google Meet', label: 'Google Meet' },
										{ _id: 'Zoom', label: 'Zoom' },
										{ _id: 'Phone', label: 'Phone' },
										{ _id: 'Start typing...', label: 'Start typing...' },
									]}
								/>
							</div>
						</div>
						<div>
							<span>Add description</span>
							<textarea
								id="description"
								name="description"
								onChange={(e) => {
									handleOnChange(e, ['description'], setData);
								}}
								defaultValue={data.description}
							></textarea>
						</div>
						<div className="flex flex-col gap-4">
							<div className="w-full flex flex-row gap-4">
								<span className="w-1/2">
									<label>Start Time</label>
									<input
										type="datetime-local"
										id="start_date"
										name="start_date"
										value={formatMongooseDate(data.time.start)}
										onChange={handleStartTimeChange}
										// onChange={(e) => {
										// 	// handleOnChange(e, ['time', 'start'], setData);
										// 	// handleOnChange(e, ['time', 'start'], setData);
										// 	setData({
										// 		...data,
										// 		time: {
										// 			start: e.target.value,
										// 			end: e.target.value,
										// 		},
										// 	});
										// }}
									/>
								</span>
								<span className="w-1/2">
									<label htmlFor="time">End Time</label>
									<input
										type="datetime-local"
										id="end_date"
										name="end_date"
										value={formatMongooseDate(data.time.end)}
										onChange={(e) => {
											handleOnChange(e, ['time', 'end'], setData);
										}}
									/>
								</span>
								<div className="">
									<label>Duration</label>
									<select defaultValue={data.duration} onChange={handleDurationChange}>
										<option value={15}>15m</option>
										<option value={30}>30m</option>
										<option value={45}>45m</option>
										<option value={60}>1h</option>
										<option value={90}>1h 30m</option>
									</select>
									{/* <span className="w-1/2">
									<label>Finish Date</label>
									<input type="date" />
								</span>
								<span className="w-1/2">
									<label>Finish Time</label>
									<input type="time" />
								</span> */}
								</div>
							</div>
						</div>
						{/* <div>
							<span>Private Notes</span>
							<textarea
								id="note"
								name="note"
								onChange={(e) => {
									handleOnChange(e, ['note'], setData);
								}}
								defaultValue={data.note}
							></textarea>
						</div> */}
						<div className="flex flex-row gap-4">
							<div className="w-1/2">
								<label>Guests</label>
								{/* <SearchSelect /> */}
								<Select
									multiple
									value={data.atendees}
									onChange={(e) => {
										handleOnChange(e, ['atendees'], setData);
									}}
									options={guestList}
								/>
							</div>
							<div className="w-1/2">
								<label>Team members</label>
								{/* <SearchSelect /> */}
								<Select
									multiple
									value={data.team}
									onChange={(e) => {
										handleOnChange(e, ['team'], setData);
									}}
									options={usersList}
								/>
							</div>
						</div>
						<button type="button" className=" bg-emerald-500 hover:bg-emerald-600" disabled={submitLock} onClick={handleSubmit}>
							{submitLock ? 'Loading...' : 'Save'}
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};
