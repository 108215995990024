// import { SelectOption } from '../components/Select';

// const handleOnChange = (
// 	e:
// 		| React.ChangeEvent<HTMLInputElement>
// 		| React.ChangeEvent<HTMLTextAreaElement>
// 		| React.ChangeEvent<HTMLSelectElement>
// 		| React.ChangeEvent<HTMLDivElement>
// 		| React.KeyboardEvent<HTMLDivElement>
// 		| React.MouseEvent<HTMLLIElement, MouseEvent>
// 		| SelectOption[]
// 		| SelectOption
// 		| string[]
// 		| string,
// 	fieldPath: string[],
// 	setData: React.Dispatch<React.SetStateAction<any>>
// ) => {
// 	let value: any;
// 	// Handle custom <Select/> components using multiple
// 	console.log(typeof e);
// 	if (Array.isArray(e)) {
// 		console.log(e);
// 		if (e.length > 0 && typeof e[0] === 'string') {
// 			value = e.map((option) => option);
// 		} else if (e.length > 0 && typeof e[0] === 'object' && 'label' in e[0]) {
// 			value = e.map((option) => ({ _id: option._id, label: option.label }));
// 		} else {
// 			value = e.map((option) => option._id);
// 		}
// 	} else {
// 		// Set event value for everything else
// 		if (typeof e === 'object' && 'target' in e && 'value' in e.target) {
// 			console.log(e.target.value);
// 			value = e.target.value;
// 			// Handle single <Select/> component
// 		} else if (typeof e === 'string') {
// 			// value = { _id: e._id, label: e.label };
// 			value = e;
// 		}
// 	}
// 	setData((prevData: any) => {
// 		let updatedData = { ...prevData };
// 		let currentLevel: any = updatedData;

// 		if (fieldPath.length > 0) {
// 			// Traverse the nested properties and update the value
// 			for (let i = 0; i < fieldPath.length - 1; i++) {
// 				const currentFieldName = fieldPath[i];
// 				currentLevel[currentFieldName] = { ...currentLevel[currentFieldName] };
// 				currentLevel = currentLevel[currentFieldName];
// 			}

// 			currentLevel[fieldPath[fieldPath.length - 1]] = value;
// 		} else {
// 			// If there is no fieldPath we will update the top level
// 			currentLevel['_id'] = value._id;
// 			currentLevel['label'] = value.label;
// 		}
// 		// console.log(fieldPath.length);

// 		return updatedData;
// 	});
// };

// export default handleOnChange;
import { SelectOption } from '../components/Select';

function isChangeEvent(e: any): e is React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> {
	return e && e.target && typeof e.target.value !== 'undefined';
}

function isSelectOption(e: any): e is SelectOption {
	return e && typeof e === 'object' && '_id' in e && 'label' in e;
}

const handleOnChange = (
	e:
		| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
		| SelectOption
		| SelectOption[]
		| string
		| string[]
		| { _id: string }
		| React.MouseEvent<HTMLLIElement, MouseEvent>,
	fieldPath: string[],
	setData: React.Dispatch<React.SetStateAction<any>>
) => {
	let value: any;

	if (Array.isArray(e)) {
		if (e.length > 0) {
			if (typeof e[0] === 'string') {
				value = e.map((option) => option);
			} else if (isSelectOption(e[0])) {
				// value = e.map((option) => ({ _id: option._id, label: option.label }));
				value = e.map((option) => {
					if (isSelectOption(option)) {
						return { _id: option._id, label: option.label };
					}
					return option; // In case it's neither a string nor SelectOption
				});
			} else if ('_id' in e[0]) {
				value = e.map((option: any) => option._id);
			} else {
				value = e;
			}
		} else {
			value = e;
		}
	} else if (isChangeEvent(e)) {
		value = e.target.value;
	} else if (typeof e === 'string') {
		value = e;
	} else if (isSelectOption(e)) {
		value = { _id: e._id, label: e.label };
	} else if (typeof e === 'object' && '_id' in e) {
		value = e._id;
	} else {
		value = e;
	}

	setData((prevData: any) => {
		const updatedData = { ...prevData };
		let currentLevel = updatedData;

		if (fieldPath.length > 0) {
			for (let i = 0; i < fieldPath.length - 1; i++) {
				const key = fieldPath[i];
				if (!(key in currentLevel)) {
					currentLevel[key] = {};
				}
				currentLevel[key] = { ...currentLevel[key] };
				currentLevel = currentLevel[key];
			}
			currentLevel[fieldPath[fieldPath.length - 1]] = value;
		} else {
			if (typeof value === 'object' && value !== null) {
				if ('_id' in value) {
					updatedData['_id'] = value['_id'];
				}
				if ('label' in value) {
					updatedData['label'] = value['label'];
				}
			}
		}
		return updatedData;
	});
};

export default handleOnChange;
